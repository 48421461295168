import { ReceiptDetailDto } from "./CustomerInteractionDto";

export interface CustomerBonusInfoDto {
  bonusXpress?: {
    threshold: number;
    cashValue: number;
  };
  coupons: CouponDto[];
  customerInfo: {
    customerStatus: "ACTIVE" | "DISABLED" | "ARCHIVED";
    mailAddress: string;
    name: string;
  };
  totalBonusPoints: number;
  availableBonusPoints: number;
}

export interface CouponDto {
  activated: string;
  advantage: {
    advantageValue: number;
    advantageType: AdvantageType;
    advantageText: string;
  };
  couponCode: string;
  expiryDate: string;
}

export enum AdvantageType {
  EURO = "EURO",
  PERCENT = "PERCENT",
  OFFER = "OFFER",
}

export interface PaymentInteractionDto {
  externalCustomerId?: string;
  customerNumber?: string;
  externalReceiptId: string;
  externalUnitNumber: string;
  interactionTimestamp: string;
  paymentAmount: number;
  bonusXpress?: EmbeddedBonusXpressDto;
  coupons?: EmbeddedCouponDto[];
  receiptDetails?: ReceiptDetailDto[];
}

export interface EmbeddedBonusXpressDto {
  cashValue: number;
}

export interface EmbeddedCouponDto {
  couponCode: string;
  cashValue?: number;
}

export interface BonusXpressInteractionDto {
  externalCustomerId?: string;
  customerNumber?: string;
  externalReceiptId: string;
  externalUnitNumber: string;
  interactionTimestamp: string;
  cashValue: number;
}

export interface BonusResponseDto {
  bonusPoints: number;
  bonusXpress?: BonusXpressResponseDto;
  totalBonusPoints: number;
}
export interface BonusXpressResponseDto {
  bonusPoints: number;
}
export interface RedemptionDto {
  couponCode: string;
  customerNumber?: string;
  externalCustomerId?: string;
  externalReceiptId: string;
  externalUnitNumber: string;
  redemptionTimestamp: string;
  cashValue?: number;
}

export interface ReversalInteractionDto {
  externalCustomerId?: string;
  customerNumber?: string;
  externalReceiptId: string;
  externalUnitNumber: string;
  interactionTimestamp: string;
  reversalAmount: number;
  externalReferenceReceiptId?: string;
  bonusXpress?: EmbeddedReversalBonusXpressInteractionDto;
  coupons?: CouponReactivationDto[];
  receiptDetails: ReceiptDetailDto[];
}
export interface EmbeddedReversalBonusXpressInteractionDto {
  reversalCashValue: number;
}
export interface CouponReactivationDto {
  couponCode: string;
}
