import React from "react";
import { NavLink as Link } from "react-router-dom";
import { Nav, NavLink, NavProps } from "reactstrap";
import {
  UrlPathBonifiedInteractions,
  UrlPathBonusPremium,
  UrlPathCoupon,
  UrlPathCustomer,
  UrlPathCustomerPreferences,
  UrlPathInviteFamily,
  UrlPathInviteFriend,
  UrlPathSimulator,
} from "../../../url";

export default class Menu extends React.Component<NavProps> {
  render() {
    return (
      <Nav {...this.props}>
        <NavLink tag={Link} to={UrlPathCustomerPreferences}>
          Vorlieben
        </NavLink>
        <NavLink tag={Link} to={UrlPathBonifiedInteractions}>
          Bonuspunkte
        </NavLink>
        <NavLink tag={Link} to={UrlPathBonusPremium}>
          Coupons
        </NavLink>
        <NavLink tag={Link} to={UrlPathCoupon}>
          Meine Coupons
        </NavLink>
        <NavLink tag={Link} to={UrlPathInviteFamily}>
          Familie
        </NavLink>
        <NavLink tag={Link} to={UrlPathInviteFriend}>
          Freunde
        </NavLink>
        <NavLink tag={Link} to={UrlPathSimulator}>
          KNISTRSHOP
        </NavLink>
        <NavLink
          tag={Link}
          to={UrlPathCustomer}
          style={{ paddingTop: 0, paddingLeft: 64 }}
        >
          <i className="bi-person-fill" style={{ fontSize: 32 }} />
        </NavLink>
      </Nav>
    );
  }
}
