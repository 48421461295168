import PreferenceQuestionDto from "../../store/models/PreferenceQuestionDto";
import { Col } from "reactstrap";
import React, { OptionHTMLAttributes } from "react";
import { PaliFinalFormSelect } from "../pali/PaliFinalFormSelect";
import PreferenceOptionDto from "../../store/models/PreferenceOptionDto";
import { PreferenceQuestionTypeDto } from "../../store/models/PreferenceQuestionTypeDto";

function buildOptions(
  options: PreferenceOptionDto[],
  multiple: boolean
): OptionHTMLAttributes<HTMLOptionElement>[] {
  const htmlOptions = options.map((option) => ({
    label: option.label,
    value: option.optionNumber,
  }));
  if (multiple) return htmlOptions;
  return [
    {
      label: "Bitte auswählen",
      value: "",
    },
    ...htmlOptions,
  ];
}

interface QuestionProps {
  question: PreferenceQuestionDto;
}

export default class Question extends React.Component<QuestionProps> {
  render() {
    const { question } = this.props;
    const multiple = question.type === PreferenceQuestionTypeDto.MULTIPLE;
    return (
      <Col md={6} className="pb-4">
        <PaliFinalFormSelect
          multiple={multiple}
          name={question.questionNumber}
          label={question.label}
          options={buildOptions(question.options, multiple)}
        />
      </Col>
    );
  }
}
