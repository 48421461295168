import API from "../../api/Api";
import { ChangePasswordDto } from "../models/ChangePasswordDto";
import { BooleanThunk } from "../rootReducer";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const thunkChangePassword =
  (changePassword: ChangePasswordDto): BooleanThunk =>
  async (dispatch) => {
    try {
      if (
        changePassword &&
        !changePassword.customerNumber &&
        !changePassword.oldPassword &&
        !changePassword.newPassword &&
        !changePassword.confirmNewPassword
      )
        return false;
      await API.changePassword(changePassword);
      dispatch(
        thunkCreateSuccessNotification("Kennwort erfolgreich aktualisiert")
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Ändern des Passworts", e)
      );
      return false;
    }
  };
