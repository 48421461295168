import { Link, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import BonusPoints from "../../../components/bonifiedInteractions/BonusPoints";
import { isLoggedIn, logout } from "../../../store/actions/LoginActions";
import {
  UrlPathBonifiedInteractions,
  UrlPathLogin,
  UrlPathMain,
} from "../../../url";
import { SelectTheme } from "../../SwitchTheme";

export const Header = () => {
  const navigate = useNavigate();
  const logoutAndRedirect = () => {
    logout();
    navigate(UrlPathLogin);
  };
  return (
    <header>
      <Navbar expand="xs">
        <NavbarBrand
          tag={isLoggedIn() ? Link : undefined}
          to={isLoggedIn() ? UrlPathMain : undefined}
        >
          <svg viewBox="0 0 100 25" id="conrad_logo" width="180" height="95">
            <g id="conrad_logo_BoundingBox">
              <rect fill="none" width="100" height="25"></rect>
            </g>
            <g id="conrad_logo_C_x5F_NRAD">
              <path
                fill="#0098DD"
                d="M18.7,20.6c-0.4,0-4.7,0-4.7,0c-4.9,0-6.7-1.6-6.7-5.9c0-6.7,3.1-10.3,8.9-10.3c0,0,5.9,0,6.2,0l1-4.3h-8.6   c-5,0-8,0.8-10.1,2.8C1.7,5.5,0,10.4,0,15.9c0,6.7,2.4,9,9.6,9h8.1l0,0L18.7,20.6z"
              ></path>
              <polygon
                fill="#0098DD"
                points="29.6,19.3 34.7,19.3 36.7,10.3 41.1,19.3 46.3,19.3 49.4,5.7 44.3,5.7 42.6,13.5 38.9,5.7    32.7,5.7 29.6,19.3  "
              ></polygon>
              <path
                fillRule="evenodd"
                fill="#0098DD"
                d="M88.9,9.2h3c2.1,0,2.7,0.5,2.7,2.1c0,2.7-1.4,4.5-3.6,4.5h-3.6L88.9,9.2L88.9,9.2z    M81.4,19.3h11c5,0,7.6-3,7.6-8.6c0-3.5-1.6-5-5.3-5H84.4L81.4,19.3L81.4,19.3z"
              ></path>
              <path
                fillRule="evenodd"
                fill="#0098DD"
                d="M63.4,17.6l-2-2.6c3.1-0.7,4.8-2.4,4.8-5.2c0-3-1.7-4.1-6-4.1h-9L48,19.3h5.3l2.3-10.1   h3.3c1.5,0,2.3,0.5,2.3,1.3c0,1.2-0.7,1.8-2.2,1.8h-3.7l2.5,4.4c1.5,2.5,1.6,2.6,4.6,2.6h5.3l0,0l1.6-2.4h5.6l-0.5,2.4h5.3   l3.1-13.6h-6.4c-4.5,0-4.6,0.1-7.5,4.2L63.4,17.6z M75.5,13.5h-4l1.6-2.4c1.1-1.7,1.5-2,3.1-2h0.4L75.5,13.5L75.5,13.5z"
              ></path>
            </g>
            <g id="conrad_logo_C_-_ueberfuellt_-_spart_aus">
              <path
                fill="#FFE600"
                d="M18.2,8.8l3.2,0l0.7-3.1l-5.7,0c-5,0-7.7,3.2-7.7,9.1c0,3.4,1.4,4.5,5.6,4.5H19l0.7-3.3H17   c-0.3,0-0.9,0-1.2,0c0,0,0,0,0,0c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.8-0.3-1.2-0.8-1.2-2   C13.5,10.5,15.2,8.8,18.2,8.8z"
              ></path>
            </g>
            <g id="conrad_logo_O_-_geteilt_-_spart_aus">
              <path
                fill="#0098DD"
                d="M24.2,5.7H22l-0.7,3l-3.2,0c-3,0-4.7,1.8-4.7,5c0,1.7,0.7,2.1,2.2,2.2c0,0,0.2,0,0.5,0   c-1.6-0.1-2.2-0.7-2.2-2.1c0-2.9,1.6-4.5,4.3-4.5l3.4,0c2.1,0,2.7,0.4,2.7,2c0,3-1.4,4.7-3.9,4.7l-0.7,0L19,19.3h3   c5.1,0,8-3.1,8-8.6C29.9,7,28.4,5.7,24.2,5.7z"
              ></path>
            </g>
          </svg>
        </NavbarBrand>
        <Nav className="ml-auto" navbar>
          {isLoggedIn() && (
            <>
              <NavItem>
                <NavLink tag={Link} to={UrlPathBonifiedInteractions}>
                  Deine Bonuspunkte:{" "}
                  <b>
                    <BonusPoints />
                  </b>
                </NavLink>
              </NavItem>
              <NavItem>
                <Button color="link" onClick={logoutAndRedirect}>
                  Logout
                </Button>
              </NavItem>
            </>
          )}
          <NavItem>
            <SelectTheme />
          </NavItem>
        </Nav>
      </Navbar>
    </header>
  );
};
