import React, { PropsWithChildren } from "react";
import { Container } from "reactstrap";
import Menu from "../../../components/app/Menu";
import NotificationList from "../../../components/notification/NotificationList";
import { Header } from "./Header";

export class MenuLayout extends React.Component<PropsWithChildren> {
  render() {
    return (
      <>
        <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
          <NotificationList />
        </div>
        <Header />
        <Menu />
        <Container>{this.props.children}</Container>
      </>
    );
  }
}
