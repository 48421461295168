import { Field } from "react-final-form";
import {
  Button,
  Col,
  Label,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import { formatEuro, nameof } from "../app/Utils";
import { CashDeskSimulatorProps } from "./CashDeskSimulator";
import ecoSign from "./images/ecoSign.png";
import { receiptDetailExamples } from "./ReceiptDetailExamples";

function getRatingIcon(val: number, rating: number): string {
  if (rating - val >= 0) return "bi-star-fill";
  if (rating - val >= -0.59) return "bi-star-half";
  return "bi-star";
}

export const ReceiptDetailPanel = () => (
  <>
    <Row>
      <Col md={6}>
        <Label for={nameof<CashDeskSimulatorProps>("receiptDetailIds")}>
          Produkte wählen
        </Label>
      </Col>
    </Row>
    <Row>
      <Field
        name={nameof<CashDeskSimulatorProps>("receiptDetailIds")}
        type="field"
      >
        {(props) =>
          receiptDetailExamples.map((example) => (
            <Col md={4} key={example.itemInfo.refNumber}>
              <Button
                color="link"
                onClick={() => {
                  return props.input.onChange(
                    props.input.value.includes(example.itemInfo.refNumber)
                      ? props.input.value.filter(
                          (refNumber: number) =>
                            refNumber !== example.itemInfo.refNumber
                        )
                      : [...props.input.value, example.itemInfo.refNumber]
                  );
                }}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <Toast
                  className={
                    props.input.value.includes(example.itemInfo.refNumber)
                      ? "bg-info"
                      : ""
                  }
                  style={{
                    textAlign: "center",
                    cursor: "hand",
                  }}
                >
                  <ToastHeader>
                    {example.itemInfo.organic && (
                      <img
                        src={ecoSign}
                        alt="Bio-zertifiziert"
                        title="Bio-zertifiziert"
                        style={{ height: "21px", paddingRight: "10px" }}
                      />
                    )}
                    {example.itemInfo.supplierItemText}
                  </ToastHeader>
                  <ToastBody style={{ padding: 0 }}>
                    <img
                      alt={example.itemInfo.supplierItemText}
                      src={`/productImages/${example.itemInfo.refNumber}.png`}
                      style={{ width: "100%" }}
                    />
                    {[1, 2, 3, 4, 5].map((val) => (
                      <i
                        key={val}
                        className={getRatingIcon(val, example.itemInfo.rating)}
                        style={{ paddingRight: 8 }}
                      />
                    ))}{" "}
                    ({example.itemInfo.votings})
                    <big style={{ paddingLeft: "128px" }}>
                      <b>{formatEuro(example.salesPrice)}</b>
                    </big>
                  </ToastBody>
                </Toast>
              </Button>
            </Col>
          ))
        }
      </Field>
    </Row>
  </>
);
