import { LOGIN_STATE } from "../actions/LoginActions";
import { PayloadAction } from "../actions/PayloadAction";

export interface ILoginReducerState {
  loginState?: boolean;
}

export const initialState: ILoginReducerState = {};

export default class LoginReducer {
  private static readonly INITIAL_STATE: ILoginReducerState = initialState;

  public static reducer(
    state: ILoginReducerState = LoginReducer.INITIAL_STATE,
    action: PayloadAction<boolean>
  ): ILoginReducerState {
    switch (action.type) {
      case LOGIN_STATE:
        return {
          ...state,
          loginState: action.payload!,
        };
      default:
        return state;
    }
  }
}
