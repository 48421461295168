export const PUBLIC_URL = window.env.PUBLIC_URL || process.env.PUBLIC_URL;
export const UrlPathMain = PUBLIC_URL + "/";
export const UrlPathCustomer = PUBLIC_URL + "/customer";
export const UrlPathLogin = PUBLIC_URL + "/login";
export const UrlPathForgotPassword = PUBLIC_URL + "/forgot-password";
export const UrlPathResetPassword = PUBLIC_URL + "/reset-password";
export const UrlPathCustomerPreferences = PUBLIC_URL + "/customerpreferences";
export const UrlPathBonifiedInteractions = PUBLIC_URL + "/bonifiedinteractions";
export const UrlPathRegistration = PUBLIC_URL + "/register";
export const UrlPathConsent = PUBLIC_URL + "/consent";
export const UrlPathCoupon = PUBLIC_URL + "/coupons";
export const UrlPathBonusPremium = PUBLIC_URL + "/premiumcoupons";
export const UrlPathSimulator = PUBLIC_URL + "/simulator";
export const UrlPathInviteFamily = PUBLIC_URL + "/invitefamily";
export const UrlPathInviteFriend = PUBLIC_URL + "/invitefriend";
export const UrlPathChangePassword = PUBLIC_URL + "/changepassword";
export const UrlPathBlockCustomer = PUBLIC_URL + "/blockcustomer";

const unauthenticatedPaths = [
  UrlPathRegistration,
  UrlPathLogin,
  UrlPathForgotPassword,
  UrlPathConsent,
  UrlPathResetPassword,
];

export const isUnauthenticatedPath = () =>
  unauthenticatedPaths.includes(window.location.pathname);
