import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { CustomerDto } from "../../store/models/CustomerDto";
import { Gender } from "../../store/models/Gender";
import { nameof } from "../app/Utils";
import { PaliFinalFormCheckbox } from "../pali/PaliFinalFormCheckbox";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";
import { PaliFinalFormSelect } from "../pali/PaliFinalFormSelect";
import { CustomerFormThunkProps } from "./CustomerFormContainer";
import { isFieldNeeded, isVisibleField } from "./Validation";
import { PaliRadioButton } from "../pali/PaliRadioButton";
import { customerTypeOptions } from "./CustomerType";

let genderOptions = [
  { value: "", label: "Bitte auswählen" },
  { value: Gender.MALE, label: "männlich" },
  { value: Gender.FEMALE, label: "weiblich" },
  { value: Gender.DIVERSE, label: "divers" },
];

export default class PersonalData extends React.Component<CustomerFormThunkProps> {
  render() {
    const {
      customer,
      branchStores,
      customerDisplayConfig,
      customerFieldConfig,
    } = this.props;
    return (
      <Card id="personal" className="mb-5">
        <CardBody>
          <CardTitle>Persönliche Daten</CardTitle>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("gender"),
                customerFieldConfig
              ) && (
                <PaliFinalFormSelect
                  name={nameof<CustomerDto>("gender")}
                  label="Geschlecht"
                  options={genderOptions}
                />
              )}
            </Col>
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("title"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<CustomerDto>("title")}
                  label="Titel"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("firstName"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<CustomerDto>("firstName")}
                  label="Vorname"
                  readOnly={Boolean(customer.firstName)}
                />
              )}
            </Col>
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("lastName"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<CustomerDto>("lastName")}
                  label="Nachname"
                  readOnly={Boolean(customer.lastName)}
                />
              )}
            </Col>
          </Row>

          <Row>
            {branchStores?.length > 0 && (
              <Col md={6}>
                {isFieldNeeded(
                  nameof<CustomerDto>("masterUnitNumber"),
                  customerFieldConfig
                ) && (
                  <PaliFinalFormSelect
                    name={nameof<CustomerDto>("masterUnitNumber")}
                    label="Meine Filiale"
                    options={[{ label: "bitte wählen...", value: "" }].concat(
                      branchStores.map((branchStore) => ({
                        label: branchStore.unitName,
                        value: branchStore.unitNumber,
                      }))
                    )}
                  />
                )}
              </Col>
            )}
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("externalCustomerId"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<CustomerDto>("externalCustomerId")}
                  label="Kundennummer (optional)"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("companyName"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<CustomerDto>("companyName")}
                  label={"Firmenname"}
                />
              )}
            </Col>
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("vatId"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<CustomerDto>("vatId")}
                  label={"USt-ID"}
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              {isFieldNeeded(
                nameof<CustomerDto>("customerType"),
                customerFieldConfig
              ) && (
                <PaliRadioButton
                  name={nameof<CustomerDto>("customerType")}
                  value={customer.customerType}
                  options={customerTypeOptions}
                  row
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              {isVisibleField(
                nameof<CustomerDto>("emailCorrespondenceAllowed"),
                customerDisplayConfig
              ) && (
                <PaliFinalFormCheckbox
                  name={nameof<CustomerDto>("emailCorrespondenceAllowed")}
                  label="Ja, ich möchte den regelmäßigen Newsletter erhalten. Die Einwilligung zum Erhalt des Newsletters können Sie jederzeit mit Wirkung für die Zukunft widerrufen, beispielsweise durch das Anklicken des Abmeldelinks am Ende jedes Newsletters."
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
