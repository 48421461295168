import React, { PropsWithChildren } from "react";
import { Container } from "reactstrap";
import { Header } from "./Header";

export class HeaderLayout extends React.Component<PropsWithChildren> {
  render() {
    return (
      <>
        <Header />
        <Container>{this.props.children}</Container>
      </>
    );
  }
}
