import React, { ChangeEventHandler, OptionHTMLAttributes } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Field } from "react-final-form";

interface PaliFinalFormSelectProps {
  name: string;
  label: string;
  options: OptionHTMLAttributes<HTMLOptionElement>[];
  multiple?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export class PaliFinalFormSelect extends React.Component<PaliFinalFormSelectProps> {
  render() {
    const { label, name, options, multiple, onChange } = this.props;
    return (
      <Field name={name} type={multiple ? "field" : "select"}>
        {({ input, meta }) => {
          const hasErrors = meta.error && meta.touched;
          return (
            <FormGroup className={!multiple ? "select" : undefined}>
              <Label for={name}>{label}</Label>
              <Input
                {...input}
                onChange={(e) => {
                  input.onChange(e);
                  onChange && onChange(e);
                }}
                type="select"
                id={name}
                multiple={multiple}
                size={multiple ? options.length : undefined}
              >
                {options.map(({ label, value, selected }) => (
                  <option selected={selected} key={String(value)} value={value}>
                    {label}
                  </option>
                ))}
              </Input>
              {hasErrors && <span className="help-block">{meta.error}</span>}
            </FormGroup>
          );
        }}
      </Field>
    );
  }
}
