import React from "react";
import { connect } from "react-redux";
import { IStore } from "../../store/rootReducer";
import {
  CouponDto,
  CouponStatus,
  formatCouponStatus,
  getCouponStatus,
} from "../../store/models/CouponDto";
import { PaliTable } from "../pali/PaliTable";
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Form } from "react-final-form";
import { PaliInput } from "../pali/PaliInput";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkRedeemCoupon } from "../../store/actions/TouchpointActions";
import { PaliFinalFormSelect } from "../pali/PaliFinalFormSelect";
import { ValidationErrors } from "final-form";
import { ColumnDescription } from "react-bootstrap-table-next";
import { getCurrentDateTimeAsString } from "../app/Utils";

// TODO the coupon status should come from the backend
function isCouponActivated(coupon: CouponDto): boolean {
  return getCouponStatus(coupon) === CouponStatus.ACTIVATED;
}

const tableColumns = (
  openRedeem: (coupon: CouponDto) => void
): ColumnDescription[] => [
  {
    dataField: "couponCode",
    text: "Couponcode",
  },
  {
    dataField: "advantage.advantageText",
    text: "Name",
    sort: true,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (
      cell: any,
      row: CouponDto,
      rowIndex: number,
      formatExtraData: any
    ) => {
      return formatCouponStatus(getCouponStatus(row));
    },
  },
  {
    dataField: "",
    text: "",
    formatter: (cell: any, row: CouponDto) => {
      if (isCouponActivated(row))
        return <Button onClick={() => openRedeem(row)}>Einlösen</Button>;
      return "";
    },
  },
];

const MAX_RESULTS_IN_TABLE = 10;

type ThunkProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

interface CouponTableFormModel {
  externalUnitNumber: string;
}

interface CouponTableState {
  pagedCoupons: CouponDto[];
  selectedCoupon?: CouponDto;
  searchTerm: string;
  currentPage: number;
  maxPage: number;
  totalEntries: number;
  isRedeemOpen: boolean;
}

export class CouponTable extends React.Component<ThunkProps, CouponTableState> {
  initialValues: CouponTableFormModel = {
    externalUnitNumber: "",
  };

  constructor(props: ThunkProps) {
    super(props);
    this.state = {
      pagedCoupons: [],
      searchTerm: "",
      currentPage: 1,
      maxPage: 1,
      totalEntries: 0,
      isRedeemOpen: false,
    };
  }
  validate = (values: CouponTableFormModel) => {
    const errors: ValidationErrors = {};
    if (!values.externalUnitNumber) {
      errors.externalUnitNumber = "Filiale erforderlich";
    }
    return errors;
  };

  static getDerivedStateFromProps(
    nextProps: ThunkProps,
    state: CouponTableState
  ) {
    if (
      state.searchTerm === "" &&
      state.totalEntries !== nextProps.coupons.length
    ) {
      return {
        pagedCoupons: CouponTable.paginate(nextProps.coupons, 1),
        maxPage: CouponTable.fetchMaxPage(nextProps.coupons),
        currentPage: 1,
        totalEntries: nextProps.coupons.length,
      };
    } else return null;
  }

  goToPage = (pageNumber: number) => () => {
    this.setState({
      currentPage: pageNumber,
      pagedCoupons: CouponTable.paginate(
        this.searchList(this.state.searchTerm),
        pageNumber
      ),
    });
  };

  static fetchMaxPage(arr: any[]) {
    return Math.floor((arr.length - 1) / MAX_RESULTS_IN_TABLE) + 1;
  }

  static paginate(array: any[], page: number) {
    --page;
    return array.slice(
      page * MAX_RESULTS_IN_TABLE,
      (page + 1) * MAX_RESULTS_IN_TABLE
    );
  }

  handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    const searchResult = this.searchList(searchTerm);
    const pagedCoupons = CouponTable.paginate(searchResult, 1);
    this.setState({
      searchTerm,
      pagedCoupons,
      maxPage: CouponTable.fetchMaxPage(searchResult),
      currentPage: 1,
    });
  };

  searchList = (searchTerm: string) => {
    let newCoupons = this.props.coupons;
    if (searchTerm.length) {
      newCoupons = newCoupons.filter((coupon) =>
        coupon.advantage.advantageText
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }
    return newCoupons;
  };

  openRedeem = (coupon: CouponDto) => {
    this.setState({
      isRedeemOpen: true,
      selectedCoupon: coupon,
    });
  };

  toggleRedeem = () => {
    this.setState((prevState) => ({
      isRedeemOpen: !prevState.isRedeemOpen,
    }));
  };

  redeem = (values: CouponTableFormModel) => {
    const { thunkRedeemCoupon } = this.props;
    const { selectedCoupon } = this.state;

    if (!selectedCoupon) return;
    thunkRedeemCoupon({
      couponCode: selectedCoupon.couponCode,
      customerNumber: selectedCoupon.activatedBy,
      externalReceiptId: "REDEEMED-FROM-LOYALTY-PORTAL",
      externalUnitNumber: values.externalUnitNumber,
      redemptionTimestamp: getCurrentDateTimeAsString(),
    });
    this.toggleRedeem();
  };

  render() {
    const {
      currentPage,
      maxPage,
      pagedCoupons,
      searchTerm,
      isRedeemOpen,
      selectedCoupon,
    } = this.state;
    const { coupons, branchStores } = this.props;

    if (!branchStores.length) return null;

    if (!coupons.length) {
      return <p>Sie haben noch keine Coupons.</p>;
    }
    return (
      <Row>
        <Col sm={3}>
          <PaliInput
            type="text"
            label="Coupon suchen"
            value={searchTerm}
            handleChange={this.handleSearchTermChange}
            name="searchTerm"
          />
        </Col>
        <Col sm={12}>
          <PaliTable
            columns={tableColumns(this.openRedeem)}
            data={pagedCoupons.map((coupon, i) => ({ ...coupon, id: i }))}
          />
          <Pagination size="sm" hidden={maxPage < 2}>
            <PaginationItem>
              <PaginationLink
                previous
                disabled={currentPage <= 1}
                onClick={this.goToPage(currentPage - 1)}
              />
            </PaginationItem>

            {Array.from(Array(maxPage).keys()).map((i) => (
              <PaginationItem
                key={"paginationItem" + i}
                active={i + 1 === currentPage}
              >
                <PaginationLink onClick={this.goToPage(i + 1)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem>
              <PaginationLink
                next
                disabled={currentPage >= maxPage}
                onClick={this.goToPage(currentPage + 1)}
              />
            </PaginationItem>
          </Pagination>
        </Col>

        <Modal isOpen={isRedeemOpen}>
          <Form
            initialValues={this.initialValues}
            onSubmit={this.redeem}
            validate={this.validate}
            render={({ handleSubmit, submitting, hasValidationErrors }) => (
              <form onSubmit={handleSubmit}>
                <ModalHeader>
                  {selectedCoupon?.advantage.advantageText}
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col md={12}>
                      <PaliFinalFormSelect
                        name="externalUnitNumber"
                        label="Filiale auswählen *"
                        options={[
                          { label: "bitte wählen...", value: "" },
                        ].concat(
                          branchStores.map((branchStore) => ({
                            label: branchStore.unitName,
                            value: branchStore.externalUnitNumber,
                          }))
                        )}
                      />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    disabled={submitting || hasValidationErrors}
                  >
                    Einlösen
                  </Button>
                  <Button onClick={this.toggleRedeem}>Schließen</Button>
                </ModalFooter>
              </form>
            )}
          />
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  coupons: state.coupon.coupons.sort((a, b) =>
    getCouponStatus(a).localeCompare(getCouponStatus(b))
  ),
  branchStores: state.operationalUnit.branchStores,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkRedeemCoupon }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CouponTable);
