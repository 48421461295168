import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from "reactstrap";
import BonusPoints from "../../../components/bonifiedInteractions/BonusPoints";
import { isLoggedIn, logout } from "../../../store/actions/LoginActions";
import {
  UrlPathBonifiedInteractions,
  UrlPathLogin,
  UrlPathMain,
} from "../../../url";
import { SelectTheme } from "../../SwitchTheme";
import logo from "../images/poco-logo.png";

export const Header = () => {
  const navigate = useNavigate();
  const logoutAndRedirect = () => {
    logout();
    navigate(UrlPathLogin);
  };
  return (
    <header>
      <Container>
        <Navbar expand="xs">
          <NavbarBrand
            tag={isLoggedIn() ? Link : undefined}
            to={isLoggedIn() ? UrlPathMain : undefined}
          >
            <img
              src={logo}
              style={{
                height: 68,
                width: 262,
              }}
              alt="POCO Einrichtungsmärkte"
            />
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            {isLoggedIn() && (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to={UrlPathBonifiedInteractions}
                    style={{ textAlign: "center" }}
                  >
                    <div
                      style={{
                        marginTop: "4px",
                        fontSize: 24,
                        marginBottom: "-2px",
                      }}
                    >
                      <b>
                        <BonusPoints />
                      </b>
                    </div>
                    <span style={{ color: "black" }}>Deine Punkte</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <Button color="link" onClick={logoutAndRedirect}>
                    <i
                      className="bi-person"
                      style={{
                        fontSize: 36,
                        lineHeight: 0,
                        padding: 0,
                        color: "black",
                      }}
                    />
                    <br />
                    <span style={{ color: "black" }}>Logout</span>
                  </Button>
                </NavItem>
              </>
            )}
            <NavItem>
              <SelectTheme />
            </NavItem>
          </Nav>
        </Navbar>
      </Container>
    </header>
  );
};
