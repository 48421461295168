import React from "react";
import { MenuLayout } from "../../themes/SwitchTheme";
import CouponTable from "./CouponTable";

export default class CouponPage extends React.Component {
  render() {
    return (
      <MenuLayout>
        <h1>Meine Coupons</h1>
        <CouponTable />
      </MenuLayout>
    );
  }
}
