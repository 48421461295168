import React from "react";
import ConsentForm from "./ConsentForm";
import { IStore } from "../../store/rootReducer";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkConsent } from "../../store/actions/ConsentActions";

export type ConsentFormThunkProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

class ConsentFormContainer extends React.Component<ConsentFormThunkProps> {
  render() {
    return <ConsentForm {...this.props} />;
  }
}

const mapStateToProps = (state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkConsent }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsentFormContainer);
