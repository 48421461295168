import { useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { connect } from "react-redux";
import { Routes, useLocation, useNavigate } from "react-router";
import { Route } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  thunkGetBonusPointsInfo,
  thunkGetCustomerHistory,
} from "../../store/actions/BonifiedInteractionsActions";
import { thunkFindActivePremiums } from "../../store/actions/BonusPremiumActions";
import { thunkFindCoupons } from "../../store/actions/CouponActions";
import { thunkFindCustomer } from "../../store/actions/CustomerActions";
import { thunkGetCustomerPreferences } from "../../store/actions/CustomerPreferencesActions";
import { ensureToken, isLoggedIn } from "../../store/actions/LoginActions";
import {
  thunkGetBranchStoreList,
  thunkGetOperationalRootUnitList,
} from "../../store/actions/OperationalUnitActions";
import { thunkGetRegistrationFieldConfig } from "../../store/actions/RegistrationActions";
import { IStore } from "../../store/rootReducer";
import {
  isUnauthenticatedPath,
  UrlPathBlockCustomer,
  UrlPathBonifiedInteractions,
  UrlPathBonusPremium,
  UrlPathChangePassword,
  UrlPathConsent,
  UrlPathCoupon,
  UrlPathCustomer,
  UrlPathCustomerPreferences,
  UrlPathForgotPassword,
  UrlPathInviteFamily,
  UrlPathInviteFriend,
  UrlPathLogin,
  UrlPathMain,
  UrlPathRegistration,
  UrlPathResetPassword,
  UrlPathSimulator,
} from "../../url";
import BlockCustomerPage from "../blockCard/BlockCustomerPage";
import BonifiedInteractionsPage from "../bonifiedInteractions/BonifiedInteractionsPage";
import BonusPremiumPage from "../bonusPremium/BonusPremiumPage";
import ChangePasswordPage from "../changePassword/ChangePasswordPage";
import ConsentPage from "../consent/ConsentPage";
import CouponPage from "../coupon/CouponPage";
import CustomerPage from "../customer/CustomerPage";
import CustomerPreferencesPage from "../customerPreferences/CustomerPreferencesPage";
import InviteFamilyPage from "../inviteFamily/InviteFamilyPage";
import InviteFriendPage from "../inviteFriend/InviteFriendPage";
import ForgotPasswordPage from "../login/ForgotPasswordPage";
import LoginPage from "../login/LoginPage";
import ResetPasswordPage from "../login/ResetPasswordPage";
import RegistrationPage from "../registration/RegistrationPage";
import CashDeskSimulatorPage from "../simulator/CashDeskSimulatorPage";

const App = (props: ThunkProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const {
      thunkFindCustomer,
      thunkGetCustomerPreferences,
      thunkGetCustomerHistory,
      thunkGetBonusPointsInfo,
      thunkFindCoupons,
      thunkFindActivePremiums,
      thunkGetOperationalRootUnitList,
      thunkGetBranchStoreList,
      thunkGetRegistrationFieldConfig,
    } = props;
    if (isUnauthenticatedPath()) {
      thunkGetRegistrationFieldConfig();
      thunkGetBranchStoreList();
    } else {
      const getToken = async () => {
        const token = await ensureToken();
        if (token) {
          thunkGetCustomerPreferences();
          thunkFindCustomer();
          thunkGetCustomerHistory();
          thunkGetBonusPointsInfo();
          thunkFindCoupons(false);
          thunkFindActivePremiums();
          thunkGetOperationalRootUnitList();
          thunkGetBranchStoreList();
        }
      };
      getToken();
    }
  }, [props, props.loginState]);

  useEffect(() => {
    if (!isUnauthenticatedPath() && !isLoggedIn()) {
      navigate(UrlPathLogin);
    }
  }, [location, navigate]);

  return (
    <Routes>
      <Route path={UrlPathMain} element={<CustomerPage />} />
      <Route path={UrlPathCustomer} element={<CustomerPage />} />
      <Route path={UrlPathChangePassword} element={<ChangePasswordPage />} />
      <Route
        path={UrlPathCustomerPreferences}
        element={<CustomerPreferencesPage />}
      />
      <Route
        path={UrlPathBonifiedInteractions}
        element={<BonifiedInteractionsPage />}
      />
      <Route path={UrlPathCoupon} element={<CouponPage />} />
      <Route path={UrlPathBonusPremium} element={<BonusPremiumPage />} />
      <Route path={UrlPathSimulator} element={<CashDeskSimulatorPage />} />
      <Route path={UrlPathInviteFamily} element={<InviteFamilyPage />} />
      <Route path={UrlPathInviteFriend} element={<InviteFriendPage />} />
      <Route path={UrlPathLogin} element={<LoginPage />} />
      <Route path={UrlPathRegistration} element={<RegistrationPage />} />
      <Route path={UrlPathForgotPassword} element={<ForgotPasswordPage />} />
      <Route path={UrlPathResetPassword} element={<ResetPasswordPage />} />
      <Route path={UrlPathConsent} element={<ConsentPage />} />
      <Route path={UrlPathBlockCustomer} element={<BlockCustomerPage />} />
    </Routes>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetCustomerPreferences,
      thunkFindCustomer,
      thunkGetCustomerHistory,
      thunkGetBonusPointsInfo,
      thunkFindCoupons,
      thunkFindActivePremiums,
      thunkGetOperationalRootUnitList,
      thunkGetBranchStoreList,
      thunkGetRegistrationFieldConfig,
    },
    dispatch
  );

type ThunkProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: IStore) => ({
  loginState: state.login.loginState,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
