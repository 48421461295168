import React from "react";
import { IStore } from "../../store/rootReducer";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import CustomerPreferencesForm from "./CustomerPreferencesForm";
import { thunkUpdateCustomerPreferences } from "../../store/actions/CustomerPreferencesActions";

type ThunkProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

class CustomerPreferencesFormContainer extends React.Component<ThunkProps> {
  render() {
    const { thunkUpdateCustomerPreferences, preference } = this.props;
    if (!preference) return null;
    return (
      <CustomerPreferencesForm
        handleSubmit={thunkUpdateCustomerPreferences}
        preference={preference}
      />
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  preference: state.customerPreferences.preference,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkUpdateCustomerPreferences }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerPreferencesFormContainer);
