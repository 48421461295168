import React, { PropsWithChildren } from "react";
import { Col, Container, Row } from "reactstrap";
import { Header } from "./Header";

export class CenterLayout extends React.Component<PropsWithChildren> {
  render() {
    return (
      <>
        <Container>
          <Header />
          <Row>
            <Col sm={{ size: 6, offset: 3 }} style={{ marginTop: 64 }}>
              {this.props.children}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
