import Api from "../../api/Api";
import { BooleanThunk } from "../rootReducer";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const BLOCK_CUSTOMER_SUCCESS = "BLOCK_CUSTOMER_SUCCESS";

export const thunkBlockCustomer = (): BooleanThunk => async (dispatch) => {
  try {
    await Api.blockCustomer();
    dispatch({
      type: BLOCK_CUSTOMER_SUCCESS,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler bei der Kartensperrung", e));
    return false;
  }
};
