import React from "react";
import { MenuLayout } from "../../themes/SwitchTheme";
import BonusPremiumTable from "./BonusPremiumTable";

export default class BonusPremiumPage extends React.Component {
  render() {
    return (
      <MenuLayout>
        <h1>Prämiencoupons</h1>
        <BonusPremiumTable />
      </MenuLayout>
    );
  }
}
