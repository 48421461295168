import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";
import { ChangePasswordFormChildProps } from "./ChangePasswordForm";

export default class ChangePasswordData extends React.Component<ChangePasswordFormChildProps> {
  render() {
    const { changePassword } = this.props;
    return (
      <Card id="changePassword" className="mb-5">
        <CardBody>
          <CardTitle>Kennwort ändern</CardTitle>
          <Row>
            <Col md={6}>
              <PaliFinalFormInput
                type="password"
                name="oldPassword"
                label="Aktuelles Kennwort"
                readOnly={Boolean(changePassword.oldPassword)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PaliFinalFormInput
                type="password"
                name="newPassword"
                label="Neues Kennwort"
                readOnly={Boolean(changePassword.newPassword)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PaliFinalFormInput
                type="password"
                name="confirmNewPassword"
                label="Neues Kennwort wiederholen"
                readOnly={Boolean(changePassword.confirmNewPassword)}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
