import countries from "iso3166-2-db/countryList/dispute/UN/de.json";
import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import AddressDto from "../../store/models/AddressDto";
import { CustomerDto } from "../../store/models/CustomerDto";
import { nameof, nameof2 } from "../app/Utils";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";
import { PaliFinalFormSelect } from "../pali/PaliFinalFormSelect";
import { CustomerFormThunkProps } from "./CustomerFormContainer";
import { isFieldNeeded } from "./Validation";

const countryList = Object.entries(countries)
  .map(([k, v]) => ({
    value: k,
    label: v.name,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export default class Contact extends React.Component<CustomerFormThunkProps> {
  render() {
    const { customer, customerFieldConfig } = this.props;
    return (
      <Card id="contact" className="mb-5">
        <CardBody>
          <CardTitle>Kontaktinformationen</CardTitle>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof2<CustomerDto, AddressDto>("address", "streetName"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof2<CustomerDto, AddressDto>(
                    "address",
                    "streetName"
                  )}
                  label="Straße"
                />
              )}
            </Col>
            <Col md={3}>
              {isFieldNeeded(
                nameof2<CustomerDto, AddressDto>("address", "houseNumber"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof2<CustomerDto, AddressDto>(
                    "address",
                    "houseNumber"
                  )}
                  label="Hausnummer"
                />
              )}
            </Col>
            <Col md={3}>
              {isFieldNeeded(
                nameof2<CustomerDto, AddressDto>("address", "addressAddition"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof2<CustomerDto, AddressDto>(
                    "address",
                    "addressAddition"
                  )}
                  label="Adresszusatz"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              {isFieldNeeded(
                nameof2<CustomerDto, AddressDto>("address", "zipCode"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof2<CustomerDto, AddressDto>("address", "zipCode")}
                  label="Postleitzahl"
                />
              )}
            </Col>
            <Col md={4}>
              {isFieldNeeded(
                nameof2<CustomerDto, AddressDto>("address", "cityName"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof2<CustomerDto, AddressDto>("address", "cityName")}
                  label="Ort"
                />
              )}
            </Col>
            <Col md={6}>
              {isFieldNeeded(
                nameof2<CustomerDto, AddressDto>("address", "country"),
                customerFieldConfig
              ) && (
                <PaliFinalFormSelect
                  name={nameof2<CustomerDto, AddressDto>("address", "country")}
                  label="Land"
                  options={countryList}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("mailAddress"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<CustomerDto>("mailAddress")}
                  label="E-Mail"
                  readOnly={Boolean(customer.mailAddress)}
                />
              )}
            </Col>
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("phoneNumber"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<CustomerDto>("phoneNumber")}
                  label="Telefon"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof<CustomerDto>("mobileNumber"),
                customerFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<CustomerDto>("mobileNumber")}
                  label="Mobil"
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
