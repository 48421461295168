import { Action, combineReducers, Reducer, ReducersMapObject } from "redux";
import { ThunkAction } from "redux-thunk";
import BlockCustomerReducer, {
  IBlockCustomerReducerState,
} from "./reducers/BlockCustomerReducer";
import BonifiedInteractionsReducer, {
  IBonifiedInteractionsReducerState,
} from "./reducers/BonifiedInteractionsReducer";
import BonusPremiumReducer, {
  IBonusPremiumReducerState,
} from "./reducers/BonusPremiumReducer";
import ChangePasswordReducer, {
  IChangePasswordReducerState,
} from "./reducers/ChangePasswordReducer";
import CouponReducer, { ICouponReducerState } from "./reducers/CouponReducer";
import CustomerPreferencesReducer, {
  ICustomerPreferencesReducerState,
} from "./reducers/CustomerPreferencesReducer";
import CustomerReducer, {
  ICustomerReducerState,
} from "./reducers/CustomerReducer";
import LoginReducer, { ILoginReducerState } from "./reducers/LoginReducer";
import NotificationsReducer, {
  INotificationsReducerState,
} from "./reducers/NotificationsReducer";
import OperationalUnitReducer, {
  IOperationalUnitReducerState,
} from "./reducers/OperationalUnitReducer";
import RegistrationReducer, {
  IRegistrationReducerState,
} from "./reducers/RegistrationReducer";
import TouchpointReducer, {
  ITouchpointReducerState,
} from "./reducers/TouchpointReducer";

export interface IStore {
  customer: ICustomerReducerState;
  registration: IRegistrationReducerState;
  customerPreferences: ICustomerPreferencesReducerState;
  bonifiedInteractions: IBonifiedInteractionsReducerState;
  notifications: INotificationsReducerState;
  coupon: ICouponReducerState;
  bonusPremium: IBonusPremiumReducerState;
  operationalUnit: IOperationalUnitReducerState;
  changePassword: IChangePasswordReducerState;
  touchpoint: ITouchpointReducerState;
  blockCustomer: IBlockCustomerReducerState;
  login: ILoginReducerState;
}

const reducerMap: ReducersMapObject = {
  customer: CustomerReducer.reducer,
  registration: RegistrationReducer.reducer,
  customerPreferences: CustomerPreferencesReducer.reducer,
  bonifiedInteractions: BonifiedInteractionsReducer.reducer,
  notifications: NotificationsReducer.reducer,
  coupon: CouponReducer.reducer,
  bonusPremium: BonusPremiumReducer.reducer,
  operationalUnit: OperationalUnitReducer.reducer,
  changePassword: ChangePasswordReducer.reducer,
  touchpoint: TouchpointReducer.reducer,
  blockCustomer: BlockCustomerReducer.reducer,
  login: LoginReducer.reducer,
};

export type BooleanThunkAction = ThunkAction<
  Promise<boolean>,
  IStore,
  null,
  Action<string>
>;

export type BooleanThunk = BooleanThunkAction | Promise<boolean>;

export type VoidThunk = ThunkAction<
  Promise<void>,
  IStore,
  null,
  Action<string>
>;

export default combineReducers(reducerMap) as Reducer<IStore>;
