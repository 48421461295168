import React from "react";
import ConsentFormContainer from "./ConsentFormContainer";
import { HeaderLayout } from "../../themes/SwitchTheme";

export default class ConsentPage extends React.Component {
  render() {
    return (
      <HeaderLayout>
        <ConsentFormContainer />
      </HeaderLayout>
    );
  }
}
