import React from "react";
import RegistrationFormContainer from "./RegistrationFormContainer";
import { HeaderLayout } from "../../themes/SwitchTheme";

export default class RegistrationPage extends React.Component {
  render() {
    return (
      <HeaderLayout>
        <RegistrationFormContainer />
      </HeaderLayout>
    );
  }
}
