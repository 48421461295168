export interface FieldConfigDto {
  client: ConfigClients;
  mandatoryFields: string[];
  optionalFields: string[];
}
export enum ConfigTypes {
  ADMIN = "ADMIN",
  LOYALTY = "LOYALTY",
}
export enum ConfigClients {
  ADMIN = "reactadmin",
  LOYALTY = "reactloyalty",
}

export function getMandatoryFields(fieldConfigs: FieldConfigDto[]): string[] {
  const mandaforyFields = fieldConfigs.find(
    (fieldConfig) => fieldConfig.client === ConfigClients.LOYALTY
  )?.mandatoryFields;
  return mandaforyFields || [];
}
export function getOptionalFields(fieldConfigs: FieldConfigDto[]) {
  const optionalFields = fieldConfigs.find(
    (fieldConfig) => fieldConfig.client === ConfigClients.LOYALTY
  )?.optionalFields;
  return optionalFields || [];
}
