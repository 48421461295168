import React, { PropsWithChildren } from "react";
import { Col, Container, Row } from "reactstrap";
import Menu from "../../../components/app/Menu";
import NotificationList from "../../../components/notification/NotificationList";
import { Header } from "./Header";

export class MenuLayout extends React.Component<PropsWithChildren> {
  render() {
    return (
      <>
        <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
          <NotificationList />
        </div>
        <Container>
          <Header />
          <Row>
            <Col sm={{ size: 5 }}>
              <Menu vertical />
            </Col>
            <Col sm={{ size: 7 }}>{this.props.children}</Col>
          </Row>
        </Container>
      </>
    );
  }
}
