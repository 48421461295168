export interface OperationalUnitDto {
  unitNumber: string;
  unitName: string;
  externalUnitNumber: string;
  unitType: OperationalUnitType;
  unitStatus: OperationalUnitStatus;
  contact?: {
    firstName?: string;
    lastName?: string;
    mailAddress?: string;
    phoneNumber?: string;
    address?: {
      streetName?: string;
      houseNumber?: string;
      zipCode?: string;
      cityName?: string;
    };
  };
  parentUnitNumber?: string;
}

export enum OperationalUnitType {
  CASH_TILL = "CASH_TILL",
  BRANCH_STORE = "BRANCH_STORE",
  RETAILER = "RETAILER",
  TRADE_ASSOCIATION = "TRADE_ASSOCIATION",
  DEPARTMENT = "DEPARTMENT",
  REGION = "REGION",
}

export enum OperationalUnitStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}
