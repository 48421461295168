import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { IStore } from "../rootReducer";
import API from "../../api/Api";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const FIND_ACTIVE_PREMIUMS = "FIND_ACTIVE_PREMIUMS";

export const thunkFindActivePremiums =
  (): ThunkAction<void, IStore, null, Action<string>> => async (dispatch) => {
    try {
      const coupons = await API.findActivePremiums();
      dispatch({
        type: FIND_ACTIVE_PREMIUMS,
        payload: coupons,
      });
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Laden der Prämiencoupons", e)
      );
      return false;
    }
  };
