import React, { PropsWithChildren } from "react";
import { Container } from "reactstrap";
import { Header } from "./Header";

export class HeaderLayout extends React.Component<PropsWithChildren> {
  render() {
    return (
      <>
        <Container>
          <Header />
          <div style={{ marginTop: 32 }}>{this.props.children}</div>
        </Container>
      </>
    );
  }
}
