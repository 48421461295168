import React from "react";
import { Form } from "react-final-form";
import { Button, Col, Row } from "reactstrap";
import PreferenceDto from "../../store/models/PreferenceDto";
import PreferenceMapDto from "../../store/models/PreferenceMapDto";
import { PreferenceQuestionTypeDto } from "../../store/models/PreferenceQuestionTypeDto";
import Question from "./Question";

export type CustomerFormProps = {
  handleSubmit: (preferenceMap: PreferenceMapDto) => void;
  preference: PreferenceDto;
};

export default class CustomerPreferencesForm extends React.Component<CustomerFormProps> {
  render() {
    const { preference, handleSubmit } = this.props;
    const preferenceMap: PreferenceMapDto = {};
    preference.questions.forEach((question) => {
      const options = question.options
        .filter((option) => option.selected)
        .map((option) => option.optionNumber);
      preferenceMap[question.questionNumber] =
        question.type === PreferenceQuestionTypeDto.MULTIPLE
          ? options
          : options[0];
    });
    return (
      <Form
        onSubmit={handleSubmit}
        initialValues={preferenceMap}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <h1>Vorlieben</h1>
            </Row>
            {preference.questions.map((question) => (
              <Row>
                <Question key={question.questionNumber} question={question} />
              </Row>
            ))}
            <Row>
              <Col md={12}>
                <Button type="submit">Speichern</Button>
              </Col>
            </Row>
          </form>
        )}
      />
    );
  }
}
