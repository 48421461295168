import { CouponDto } from "./CouponDto";
import { CustomerInteractionDto } from "./CustomerInteractionDto";

export interface CustomerHistoryDto {
  customerNumber: string;
  entityType: EntityType;
  eventTimestamp: string;
  eventType: EventType;
  eventUser: string;
  historyNumber: string;
  interaction?: CustomerInteractionDto;
  coupon?: CouponDto;
}

export enum EntityType {
  CUSTOMER = "CUSTOMER",
  COUPON = "COUPON",
  CUSTOMER_STATUS = "CUSTOMER_STATUS",
  ADDRESS = "ADDRESS",
  PREFERENCE = "PREFERENCE",
  INTERACTION = "INTERACTION",
}

export enum EventType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}
