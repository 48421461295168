import React from "react";
import { NavLink as Link } from "react-router-dom";
import { Nav, NavLink, NavProps } from "reactstrap";
import {
  UrlPathBlockCustomer,
  UrlPathBonifiedInteractions,
  UrlPathBonusPremium,
  UrlPathChangePassword,
  UrlPathCoupon,
  UrlPathCustomer,
  UrlPathCustomerPreferences,
  UrlPathInviteFamily,
  UrlPathInviteFriend,
  UrlPathSimulator,
} from "../../url";

export default class Menu extends React.Component<NavProps> {
  render() {
    return (
      <Nav {...this.props}>
        <NavLink tag={Link} to={UrlPathCustomer}>
          Mein Profil
        </NavLink>
        <NavLink tag={Link} to={UrlPathChangePassword}>
          Kennwort
        </NavLink>
        <NavLink tag={Link} to={UrlPathBlockCustomer}>
          Karte sperren
        </NavLink>
        <NavLink tag={Link} to={UrlPathCustomerPreferences}>
          Vorlieben
        </NavLink>
        <NavLink tag={Link} to={UrlPathBonifiedInteractions}>
          Bonuspunkte
        </NavLink>
        <NavLink tag={Link} to={UrlPathBonusPremium}>
          Prämien
        </NavLink>
        <NavLink tag={Link} to={UrlPathCoupon}>
          Meine Coupons
        </NavLink>
        <NavLink tag={Link} to={UrlPathSimulator}>
          KNISTRSHOP
        </NavLink>
        <NavLink tag={Link} to={UrlPathInviteFamily}>
          Familie
        </NavLink>
        <NavLink tag={Link} to={UrlPathInviteFriend}>
          Freunde
        </NavLink>
      </Nav>
    );
  }
}
