import CustomerForm from "./CustomerForm";
import { IStore } from "../../store/rootReducer";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  thunkGetCustomerConfig,
  thunkGetCustomerFieldConfig,
  thunkUpdateCustomer,
} from "../../store/actions/CustomerActions";
import { thunkCancelMembership } from "../../store/actions/MembershipCancellationActions";
import { useState } from "react";
import { isUnauthenticatedPath } from "../../url";
import { isLoggedIn } from "../../store/actions/LoginActions";

const CustomerFormContainer = (props: CustomerFormThunkProps) => {
  const [loadingData, setLoadingData] = useState(false);
  async function getCustomerDisplayConfig() {
    if (!props.customerDisplayConfig?.length) {
      await props.thunkGetCustomerConfig();
      await props.thunkGetCustomerFieldConfig();
      setLoadingData(true);
    }
  }
  if (!isUnauthenticatedPath() && !isLoggedIn()) {
    return null;
  }
  getCustomerDisplayConfig();
  return <>{loadingData && <CustomerForm {...props} />} </>;
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customer.customer,
  branchStores: state.operationalUnit.branchStores,
  customerDisplayConfig: state.customer.customerDisplayConfig,
  customerFieldConfig: state.customer.customerFieldConfig,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateCustomer,
      thunkCancelMembership,
      thunkGetCustomerConfig,
      thunkGetCustomerFieldConfig,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CustomerFormThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerFormContainer);
