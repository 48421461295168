import React from "react";
import { MenuLayout } from "../../themes/SwitchTheme";
import CustomerPreferencesFormContainer from "./CustomerPreferencesFormContainer";

export default class CustomerPage extends React.Component {
  render() {
    return (
      <MenuLayout>
        <CustomerPreferencesFormContainer />
      </MenuLayout>
    );
  }
}
