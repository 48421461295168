import "bootstrap/dist/css/bootstrap.min.css";
import React, { PropsWithChildren } from "react";
import { Input } from "reactstrap";
import { UrlPathMain } from "../url";
// all themes have to be included at compile time, dynamic loading of sass is not supported by webpack
import "./atu/theme.scss";
import "./bijou/theme.scss";
import "./conrad/theme.scss";
import "./engelhorn/theme.scss";
import "./futterhaus/theme.scss";
import "./hagebau/theme.scss";
import "./knistr/theme.scss";
import "./konsum/theme.scss";
import "./pali/theme.scss";
import "./poco/theme.scss";
import "./popken/theme.scss";
import "./seaside/theme.scss";
import "./toom/theme.scss";
import "./weltbild/theme.scss";
import "./wolford/theme.scss";

const icon = require(`./${pathToTheme()}/images/favicon.ico`);

function themes() {
  return {
    pali: "Pali",
    atu: "A.T.U",
    seaside: "Seaside No.1",
    weltbild: "Weltbild",
    popken: "Ulla Popken",
    bijou: "Bijou Brigitte",
    poco: "POCO",
    conrad: "Conrad",
    hagebau: "Hagebau",
    futterhaus: "Futterhaus",
    wolford: "Wolford",
    toom: "Toom",
    engelhorn: "Engelhorn",
    konsum: "Konsum",
    knistr: "Knistr",
  };
}

export const {
  HeaderLayout,
} = require(`./${pathToTheme()}/layout/HeaderLayout.tsx`);
export const {
  MenuLayout,
} = require(`./${pathToTheme()}/layout/MenuLayout.tsx`);
export const {
  CenterLayout,
} = require(`./${pathToTheme()}/layout/CenterLayout.tsx`);

export default class SwitchTheme extends React.Component<PropsWithChildren> {
  render() {
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    favicon.href = icon;
    return <div id={pathToTheme()}>{this.props.children}</div>;
  }
}

export function pathToTheme() {
  return (
    getThemeFromSubdomain() ||
    getThemeFromQueryParameter() ||
    localStorage.getItem("theme") ||
    "knistr"
  );
}

function getThemeFromSubdomain() {
  const subdomain = window.location.hostname.split(".")[0];
  return subdomain in themes() && subdomain;
}

function getThemeFromQueryParameter() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const theme = params.get("theme");
  if (theme && theme in themes()) {
    localStorage.setItem("theme", theme);
    return theme;
  }
  return null;
}

function showTheme() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const showTheme = params.get("showTheme");
  return Boolean(showTheme);
}

function selectThemeVisible() {
  return !getThemeFromSubdomain() && showTheme();
}

export const SelectTheme = () =>
  // Show the theme selector if all applies:
  // 1. The theme can't be inferred from the subdomain (e.g. conrad.loyaltyportal.dev)
  // 2. ?showTheme=true is set
  selectThemeVisible() ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Input
        type="select"
        size={1}
        value={pathToTheme()}
        onChange={(e) => {
          localStorage.setItem("theme", e.target.value);
          window.location.reload();
        }}
      >
        {Object.entries(themes()).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </Input>
      <i
        className="bi-x-circle"
        style={{ paddingLeft: 8 }}
        onClick={() => {
          window.location.href = UrlPathMain;
        }}
      />
    </div>
  ) : null;
