import React from "react";
import { connect } from "react-redux";
import { IStore } from "../../store/rootReducer";

class BonusPoints extends React.Component<ThunkProps> {
  render() {
    const { bonusPoints } = this.props;
    if (!bonusPoints) return null;
    if (bonusPoints.availableBonusPoints === bonusPoints.totalBonusPoints)
      return bonusPoints.totalBonusPoints;
    return `${bonusPoints.availableBonusPoints} (${bonusPoints.totalBonusPoints} gesamt)`;
  }
}

type ThunkProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: IStore) => ({
  bonusPoints: state.bonifiedInteractions.bonusPoints,
});

export default connect(mapStateToProps)(BonusPoints);
