import { Gender } from "./Gender";
import AddressDto from "./AddressDto";
import { nameof, nameof2 } from "../../components/app/Utils";
import { CustomerType } from "../../components/customer/CustomerType";

export interface RegistrationDto {
  login: LoginDataDto;
  mailAddress: string;
  gender?: Gender;
  title?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  masterUnitNumber?: string;
  address?: AddressDto;
  externalCustomerId?: string;
  acceptPrivacyPolicy: boolean;
  acceptTermsOfUse: boolean;
  emailCorrespondenceAllowed: boolean;
  letterCorrespondenceAllowed?: boolean;
  customerType?: CustomerType;
  companyName?: string;
  vatId?: string;
}
export const REGISTRATION_FIELDS = {
  LOGIN_USERNAME: nameof2<RegistrationDto, LoginDataDto>("login", "username"),
  LOGIN_PASSWORD: nameof2<RegistrationDto, LoginDataDto>("login", "password"),
  LOGIN_PASSWORDCONFIRM: nameof2<RegistrationDto, LoginDataDto>(
    "login",
    "passwordConfirm"
  ),
  MAILADDRESS: nameof2<RegistrationDto, LoginDataDto>("login", "username"),
  GENDER: nameof<RegistrationDto>("gender"),
  TITLE: nameof<RegistrationDto>("title"),
  FIRSTNAME: nameof<RegistrationDto>("firstName"),
  LASTNAME: nameof<RegistrationDto>("lastName"),
  DATEOFBIRTH: nameof<RegistrationDto>("dateOfBirth"),
  PHONENUMBER: nameof<RegistrationDto>("phoneNumber"),
  MOBILENUMBER: nameof<RegistrationDto>("mobileNumber"),
  MASTERUNITNUMBER: nameof<RegistrationDto>("masterUnitNumber"),
  ADDRESS_STREETNAME: nameof2<RegistrationDto, AddressDto>(
    "address",
    "streetName"
  ),
  ADDRESS_HOUSENUMBER: nameof2<RegistrationDto, AddressDto>(
    "address",
    "houseNumber"
  ),
  ADDRESS_ADDRESSADDITION: nameof2<RegistrationDto, AddressDto>(
    "address",
    "addressAddition"
  ),
  ADDRESS_ZIPCODE: nameof2<RegistrationDto, AddressDto>("address", "zipCode"),
  ADDRESS_CITYNAME: nameof2<RegistrationDto, AddressDto>("address", "cityName"),
  ADDRESS_COUNTRY: nameof2<RegistrationDto, AddressDto>("address", "country"),
  EXTERNALCUSTOMERID: nameof<RegistrationDto>("externalCustomerId"),
  ACCEPTPRIVACYPOLICY: nameof<RegistrationDto>("acceptPrivacyPolicy"),
  ACCEPTTERMSOFUSE: nameof<RegistrationDto>("acceptTermsOfUse"),
  EMAILCORRESPONDENCEALLOWED: nameof<RegistrationDto>(
    "emailCorrespondenceAllowed"
  ),
  LETTERCORRESPONDENCEALLOWED: nameof<RegistrationDto>(
    "letterCorrespondenceAllowed"
  ),
  CUSTOMERTYPE: nameof<RegistrationDto>("customerType"),
  COMPANYNAME: nameof<RegistrationDto>("companyName"),
  VATID: nameof<RegistrationDto>("vatId"),
};
export interface LoginDataDto {
  username: string;
  password: string;
  passwordConfirm?: string;
}
