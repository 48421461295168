import React from "react";
import { Alert } from "reactstrap";

interface PaliAlertProps {
  color: "danger" | "info" | "success";
  message: string;
  beforeDismiss?: () => void;
}

interface PaliAlertState {
  visible: boolean;
}

const iconByColor = {
  danger: "exclamation",
  info: "info",
  success: "check",
};

export class PaliAlert extends React.Component<PaliAlertProps, PaliAlertState> {
  constructor(props: PaliAlertProps) {
    super(props);

    this.state = {
      visible: true,
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    const { beforeDismiss } = this.props;
    beforeDismiss && beforeDismiss();
    this.setState({ visible: false });
  }
  render() {
    const { message, color } = this.props;
    return (
      <Alert color={color} isOpen={this.state.visible} toggle={this.onDismiss}>
        <i
          className={`bi-${iconByColor[color]}-circle`}
          style={{ paddingRight: 8 }}
        ></i>
        <span className="alert-message">{message}</span>
      </Alert>
    );
  }
}
