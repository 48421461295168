import React, { PropsWithChildren } from "react";
import { Col, Container, Row } from "reactstrap";
import { Header } from "./Header";

export class CenterLayout extends React.Component<PropsWithChildren> {
  render() {
    return (
      <>
        <Container className="full-height" style={{ maxWidth: "unset" }}>
          <Row className="full-height">
            <Col sm={{ size: 2 }} className="ps-0">
              <Header />
            </Col>
            <Col sm={{ size: 4, offset: 3 }} style={{ marginTop: 64 }}>
              {this.props.children}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
