import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

interface PaliInputProps {
  type: InputType;
  value?: any;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
  readOnly?: boolean;
  revalidateFormFieldsOnChange?: string[];
}

export class PaliInput extends React.Component<PaliInputProps> {
  onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { handleChange } = this.props;
    if (handleChange) {
      handleChange(e);
    }
  };

  render() {
    let { label, type, name, value, readOnly } = this.props;
    if (value === null || value === undefined) {
      value = "";
    }
    let hasErrors = false;
    return (
      <FormGroup className={hasErrors ? "has-error" : ""}>
        <Label for={name}>{label}</Label>
        <Input
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={this.onChangeHandler}
          readOnly={readOnly}
        />
        {hasErrors && <span className="help-block">{"errorMessage"}</span>}
      </FormGroup>
    );
  }
}
