import { getCurrentDateAsString } from "../../components/app/Utils";
import { AdvantageType } from "./BonusPremiumDto";

export interface AdvantageDto {
  advantageValue: number;
  advantageType: AdvantageType;
  advantageText: string;
}

export enum CouponStatus {
  ACTIVATED = "ACTIVATED",
  REDEEMED = "REDEEMED",
  EXPIRED = "EXPIRED",
}

export interface CouponDto {
  couponNumber: string;
  couponCode: string;
  familyNumber: string;
  templateNumber: string;
  advantage: AdvantageDto;
  unitNumber: string;
  activated: string;
  activatedBy: string;
  redeemed: string;
  redeemedBy?: string;
  expired: string;
  externalReceiptId?: string;
}

// TODO the coupon status should come from the backend
export function getCouponStatus(coupon: CouponDto): CouponStatus {
  if (coupon.redeemed) return CouponStatus.REDEEMED;
  if (coupon.expired && coupon.expired < getCurrentDateAsString())
    return CouponStatus.EXPIRED;
  return CouponStatus.ACTIVATED;
}

export function formatCouponStatus(couponStatus: CouponStatus): string {
  switch (couponStatus) {
    case CouponStatus.REDEEMED:
      return "eingelöst";
    case CouponStatus.EXPIRED:
      return "abgelaufen";
    case CouponStatus.ACTIVATED:
      return "aktiviert";
  }
}
