import { Link, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavbarBrand, NavItem, NavLink } from "reactstrap";
import BonusPoints from "../../../components/bonifiedInteractions/BonusPoints";
import { isLoggedIn, logout } from "../../../store/actions/LoginActions";
import {
  UrlPathBonifiedInteractions,
  UrlPathLogin,
  UrlPathMain,
} from "../../../url";
import { SelectTheme } from "../../SwitchTheme";
import logo from "../images/IMS-Logo.png";

export const Header = () => {
  const navigate = useNavigate();
  const logoutAndRedirect = () => {
    logout();
    navigate(UrlPathLogin);
  };
  return (
    <header>
      <Navbar expand="xs">
        <NavbarBrand
          tag={isLoggedIn() ? Link : undefined}
          to={isLoggedIn() ? UrlPathMain : undefined}
        >
          <img
            src={logo}
            alt="Ingenico Marketing Solutions"
            style={{
              height: 80,
              width: 124,
              margin: -16,
            }}
          />
          <span className="ml-4">Loyalty Portal</span>
        </NavbarBrand>
        <Nav className="ml-auto" navbar>
          {isLoggedIn() && (
            <>
              <NavItem>
                <NavLink tag={Link} to={UrlPathBonifiedInteractions}>
                  Deine Bonuspunkte:{" "}
                  <b>
                    <BonusPoints />
                  </b>
                </NavLink>
              </NavItem>
              <NavItem>
                <Button color="link" onClick={logoutAndRedirect}>
                  Logout
                </Button>
              </NavItem>
            </>
          )}
          <NavItem>
            <SelectTheme />
          </NavItem>
        </Nav>
      </Navbar>
    </header>
  );
};
