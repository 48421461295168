import React from "react";
import ChangePasswordFormContainer from "./ChangePasswordFormContainer";
import { MenuLayout } from "../../themes/SwitchTheme";

export default class ChangePasswordPage extends React.Component {
  render() {
    return (
      <MenuLayout>
        <ChangePasswordFormContainer />
      </MenuLayout>
    );
  }
}
