import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import App from "./components/app/App";
import Reducer from "./store/rootReducer";
import SwitchTheme from "./themes/SwitchTheme";

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_SERVER_URL: string;
      REACT_APP_KEYCLOAK_URL: string;
    }
  }
  interface Window {
    env: NodeJS.ProcessEnv;
  }
}

const store = createStore(Reducer, applyMiddleware(thunk));
const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);
root.render(
  <Provider store={store}>
    <SwitchTheme>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SwitchTheme>
  </Provider>
);
