export default interface NotificationDto {
  type: NotificationType;
  timestamp: Date;
  message: string;
  id?: string;
}

export enum NotificationType {
  ERROR = "danger",
  INFO = "info",
  CONFIRMATION = "success",
}
