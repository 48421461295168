import React from "react";
import { ColumnDescription } from "react-bootstrap-table-next";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { BranchStoreDto } from "../../store/models/BranchStoreDto";
import {
  CustomerInteractionDto,
  InteractionType,
} from "../../store/models/CustomerInteractionDto";
import {
  CustomerInteractionType,
  formatCustomerInteractionType,
} from "../../store/models/CustomerInteractionType";
import {
  CustomerHistoryDto,
  EntityType,
  EventType,
} from "../../store/models/HistoryDto";
import { IStore } from "../../store/rootReducer";
import { formatEuro, formatGermanDateTimeString, nameof } from "../app/Utils";
import { PaliTable } from "../pali/PaliTable";

const mapHistoryIcon = (row: CustomerHistoryDto): string | undefined => {
  if (row.entityType !== EntityType.INTERACTION) return;
  switch (row.interaction?.interactionType) {
    case InteractionType.BONUS_XPRESS_INTERACTION:
      return "cash";
    case InteractionType.PAYMENT_INTERACTION:
      return "cart3";
    case InteractionType.COUPON_INTERACTION:
      return "card-text";
    case InteractionType.REVERSAL_INTERACTION:
      return "backspace";
    case InteractionType.BONUS_XPRESS_REVERSAL_INTERACTION:
      return "backspace";
    case InteractionType.CUSTOMER_INTERACTION:
      switch (row.interaction.customerInteractionType) {
        case CustomerInteractionType.ADD_ADDRESS:
          return "mailbox";
        case CustomerInteractionType.REGISTER:
          return "person-plus";
        case CustomerInteractionType.ADD_PREFERENCES:
          return "gear";
      }
  }
};

const formatEntityType = (
  row: CustomerHistoryDto,
  branchStores: BranchStoreDto[]
): string | undefined => {
  switch (row.entityType) {
    case EntityType.ADDRESS:
      return "Adressdaten";
    case EntityType.CUSTOMER:
      return "Kundendaten";
    case EntityType.COUPON:
      if (row.eventType === EventType.CREATE && !row.coupon?.redeemedBy)
        return "Coupon hinzugefügt";
      if (row.eventType === EventType.UPDATE && row.coupon?.redeemedBy)
        return "Coupon eingelöst";
      if (row.eventType === EventType.UPDATE && !row.coupon?.redeemedBy)
        return "Coupon reaktiviert";
      return "Coupon";
    case EntityType.CUSTOMER_STATUS:
      return "Kundenstatus";
    case EntityType.PREFERENCE:
      return "Präferenzen";
    case EntityType.INTERACTION:
      return formatInteractionType(row.interaction!, branchStores);
  }
};

const formatInteractionType = (
  interaction: CustomerInteractionDto,
  branchStores: BranchStoreDto[]
) => {
  switch (interaction.interactionType) {
    case InteractionType.CUSTOMER_INTERACTION:
      return formatCustomerInteractionType(
        interaction.customerInteractionType!
      );
    case InteractionType.PAYMENT_INTERACTION:
      const paymentBranchStore = branchStores.find(
        (unit) => unit.externalUnitNumber === interaction.externalUnitNumber
      );
      return `Einkauf${
        paymentBranchStore ? " | " + paymentBranchStore.unitName : ""
      }`;
    case InteractionType.REVERSAL_INTERACTION:
    case InteractionType.BONUS_XPRESS_REVERSAL_INTERACTION:
      const reversalBranchStore = branchStores.find(
        (branchStore) =>
          branchStore.externalUnitNumber === interaction?.externalUnitNumber
      );

      return `${
        interaction.interactionType ===
        InteractionType.BONUS_XPRESS_REVERSAL_INTERACTION
          ? "Storno Bonuseinlösung direkt"
          : "Storno"
      }${reversalBranchStore ? " | " + reversalBranchStore.unitName : ""}`;
    case InteractionType.COUPON_INTERACTION:
      return "Coupon hinzugefügt";
    case InteractionType.BONUS_XPRESS_INTERACTION:
      return "Bonuseinlösung direkt";
    case InteractionType.ADJUSTMENT_EARN_INTERACTION:
      return "Kulanzgutschrift";
    case InteractionType.ADJUSTMENT_BURN_INTERACTION:
      return "Belastung";
    default:
      return interaction?.interactionType;
  }
};

const tableColumns = (
  openDetailModal: (history: CustomerHistoryDto) => void,
  branchStores: BranchStoreDto[]
): ColumnDescription[] => [
  {
    headerStyle: () => {
      return { width: "160px" };
    },
    dataField: nameof<CustomerHistoryDto>("eventTimestamp"),
    text: "Datum",
    sort: true,
    formatter: (cell: any, row: CustomerHistoryDto) =>
      formatGermanDateTimeString(
        row.interaction?.interactionTimestamp || row.eventTimestamp
      ),
  },
  {
    headerStyle: () => {
      return { width: "50px" };
    },
    dataField: nameof<CustomerHistoryDto>("eventType"),
    text: "",
    formatter: (cell: any, row: CustomerHistoryDto) => {
      var mapping = mapHistoryIcon(row);
      return mapping ? (
        <i className={`bi-${mapping}`} aria-hidden="true" />
      ) : (
        ""
      );
    },
  },
  {
    dataField: nameof<CustomerHistoryDto>("entityType"),
    text: "Interaktion",
    sort: true,
    formatter: (cell: any, row: CustomerHistoryDto) => {
      return formatEntityType(row, branchStores) || "";
    },
  },
  {
    headerStyle: () => {
      return { width: "100px" };
    },
    dataField: "",
    text: "Betrag",
    sort: true,
    formatter: (cell: any, row: CustomerHistoryDto) => {
      return formatEuro(
        row.interaction!.paymentAmount ||
          row.interaction!.cashValue ||
          row.interaction!.reversalAmount
      );
    },
  },
  {
    headerStyle: () => {
      return { width: "100px" };
    },
    dataField: "interaction.bonusPoints",
    text: "Punkte",
    sort: true,
    formatter: (cell: any, row: CustomerHistoryDto) =>
      row.interaction?.bonusPoints || "",
  },
  {
    headerStyle: () => {
      return { width: "120px" };
    },
    dataField: "interactionNumber",
    text: "",
    formatter: (cell: any, row: CustomerHistoryDto) => {
      return row.interaction?.interactionType ===
        InteractionType.PAYMENT_INTERACTION ? (
        <Button onClick={() => openDetailModal(row)}>Details</Button>
      ) : (
        ""
      );
    },
  },
];

const MAX_RESULTS_IN_TABLE = 10;

type ThunkProps = ReturnType<typeof mapStateToProps>;

interface BonifiedInteractionsTableState {
  history: CustomerHistoryDto[];
  currentPage: number;
  maxPage: number;
  totalEntries: number;
  detailModalOpen: boolean;
  selectedHistory?: CustomerHistoryDto;
}

export class BonifiedInteractionsTable extends React.Component<
  ThunkProps,
  BonifiedInteractionsTableState
> {
  constructor(props: ThunkProps) {
    super(props);
    this.state = {
      history: [],
      currentPage: 1,
      maxPage: 1,
      totalEntries: 0,
      detailModalOpen: false,
    };
  }

  static getDerivedStateFromProps(
    nextProps: ThunkProps,
    state: BonifiedInteractionsTableState
  ) {
    if (state.totalEntries !== nextProps.history.length) {
      return {
        history: BonifiedInteractionsTable.paginate(nextProps.history, 1),
        maxPage: BonifiedInteractionsTable.fetchMaxPage(nextProps.history),
        currentPage: 1,
        totalEntries: nextProps.history.length,
      };
    } else return null;
  }

  goToPage = (pageNumber: number) => () => {
    this.props.history &&
      this.setState({
        currentPage: pageNumber,
        history: BonifiedInteractionsTable.paginate(
          this.props.history,
          pageNumber
        ),
      });
  };

  static fetchMaxPage(arr: any[]) {
    return Math.floor((arr.length - 1) / MAX_RESULTS_IN_TABLE) + 1;
  }

  static paginate(array: any[], page: number) {
    --page;
    return array.slice(
      page * MAX_RESULTS_IN_TABLE,
      (page + 1) * MAX_RESULTS_IN_TABLE
    );
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      detailModalOpen: !prevState.detailModalOpen,
    }));
  };

  openDetailModal = (selectedHistory: CustomerHistoryDto) => {
    this.setState({
      detailModalOpen: true,
      selectedHistory,
    });
  };

  render() {
    const { currentPage, maxPage, history, detailModalOpen, selectedHistory } =
      this.state;

    const { branchStores } = this.props;
    if (!branchStores.length) return null;

    if (!this.props.history?.length) {
      return <p>Sie haben noch keine Buchungen.</p>;
    }

    return (
      <Row>
        <Col sm={12}>
          <PaliTable
            columns={tableColumns(this.openDetailModal, branchStores)}
            data={history.map((entry) => ({
              ...entry,
              id: entry.historyNumber,
            }))}
          />
          <Pagination
            size="sm"
            aria-label="mehr Bonuspunkte anzeigen"
            hidden={maxPage < 2}
          >
            <PaginationItem>
              <PaginationLink
                previous
                disabled={currentPage <= 1}
                onClick={this.goToPage(currentPage - 1)}
              />
            </PaginationItem>

            {Array.from(Array(maxPage).keys()).map((i) => (
              <PaginationItem
                key={"paginationItem" + i}
                active={i + 1 === currentPage}
              >
                <PaginationLink onClick={this.goToPage(i + 1)}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            <PaginationItem>
              <PaginationLink
                next
                disabled={currentPage >= maxPage}
                onClick={this.goToPage(currentPage + 1)}
              />
            </PaginationItem>
          </Pagination>
        </Col>
        <Modal toggle={this.toggleModal} isOpen={detailModalOpen}>
          <ModalHeader>Transaktionsdetails</ModalHeader>
          <ModalBody>
            {selectedHistory && (
              <>
                <p>
                  Datum:{" "}
                  {formatGermanDateTimeString(
                    selectedHistory.interaction?.interactionTimestamp ||
                      selectedHistory.eventTimestamp
                  )}
                </p>
                {selectedHistory.entityType === EntityType.INTERACTION && (
                  <p>
                    Rechnungsbetrag:{" "}
                    {selectedHistory.interaction &&
                      selectedHistory.interaction.paymentAmount &&
                      formatEuro(selectedHistory.interaction.paymentAmount)}
                  </p>
                )}
                {selectedHistory.interaction &&
                  selectedHistory.interaction.rewardedInteractions.map(
                    (interaction, index) => (
                      <React.Fragment key={index}>
                        <p>Bonus: {interaction.bonusReason}</p>
                        <p>Punkte: {Number(interaction.bonusPoints)}</p>
                      </React.Fragment>
                    )
                  )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleModal}>Schließen</Button>
          </ModalFooter>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = (state: IStore) => ({
  history: state.bonifiedInteractions.history.filter(
    (history) => history.interaction?.bonusPoints
  ),
  branchStores: state.operationalUnit.branchStores,
});

export default connect(mapStateToProps)(BonifiedInteractionsTable);
