import API from "../../api/Api";
import { ForgotPasswordDto } from "../models/ForgotPasswordDto";
import { ResetPasswordDto } from "../models/ResetPasswordDto";
import { BooleanThunk } from "../rootReducer";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

function getTokenFromSearchParam() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get("token") || undefined;
}

export const thunkForgotPassword =
  (forgotPassword: ForgotPasswordDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const promise = await API.forgotPassword(forgotPassword);
      console.log(promise);
      dispatch(
        thunkCreateSuccessNotification(
          "Der Link für das Zurücksetzen Ihres Passworts an Ihre E-Mailadresse gesendet"
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Zurücksetzen des Passworts:",
          e
        )
      );
      return false;
    }
  };
export const thunkResetPassword =
  (resetPassword: ResetPasswordDto): BooleanThunk =>
  async (dispatch) => {
    try {
      resetPassword.token = getTokenFromSearchParam();
      await API.resetPassword(resetPassword);
      dispatch(
        thunkCreateSuccessNotification("Passwort erfolgreich aktualisiert")
      );
      return true;
    } catch (e) {
      if (e === "Token authentication failed") {
        dispatch(
          thunkCreateErrorNotification(
            "Der Link zur Wiederherstellung des Passworts ist abgelaufen. Bitte fordern Sie einen neuen Link an.",
            e
          )
        );
      } else {
        dispatch(
          thunkCreateErrorNotification(
            "Fehler beim Zurücksetzen des Passworts",
            e
          )
        );
      }
      return false;
    }
  };
