import { ValidationErrors } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  thunkCreatePaymentInteraction,
  thunkGetCustomerBonusInfo,
} from "../../store/actions/TouchpointActions";
import { OperationalUnitType } from "../../store/models/OperationalUnitDto";
import {
  AdvantageType,
  CouponDto,
  EmbeddedCouponDto,
} from "../../store/models/TouchpointDto";
import { IStore } from "../../store/rootReducer";
import { formatEuro } from "../app/Utils";
import { PaliFinalFormSelect } from "../pali/PaliFinalFormSelect";
import { BonusXpressPanel } from "./BonusXpressPanel";
import { CouponPanel } from "./CouponPanel";
import { receiptDetailExamples } from "./ReceiptDetailExamples";
import { ReceiptDetailPanel } from "./ReceiptDetailPanel";
import ReceiptSimulator from "./ReceiptSimulator";

type ThunkProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export type CashDeskSimulatorProps = {
  externalUnitNumber: string;
  selectedCoupons?: CouponDto[];
  cashValue?: number;
  receiptDetailIds: string[];
};

export const calculatePaymentAmountWithCouponAndXpress = (
  paymentAmount: number,
  cashValue?: number,
  selectedCoupons?: CouponDto[]
) => {
  const paymentAmountWithCoupon = calculatePaymentAmountWithCoupons(
    paymentAmount,
    selectedCoupons
  );
  return paymentAmountWithCoupon - (cashValue || 0);
};

export const calculatePaymentAmountWithCoupons = (
  paymentAmount: number,
  selectedCoupons?: CouponDto[]
) => {
  let cashValue =
    selectedCoupons?.reduce(
      (cashValue, coupon) =>
        cashValue + (calculateCoupon(paymentAmount, coupon) || 0),
      0
    ) || 0;
  return paymentAmount - cashValue;
};

export const calculateCoupon = (amount: number, coupon: CouponDto) => {
  if (coupon && coupon.advantage.advantageValue) {
    if (coupon.advantage.advantageType === AdvantageType.PERCENT)
      return (amount * coupon.advantage.advantageValue) / 100;
    if (coupon.advantage.advantageType === AdvantageType.EURO)
      return amount >= 0
        ? coupon.advantage.advantageValue
        : -coupon.advantage.advantageValue;
  }
};

const calculateCoupons = (
  paymentAmount: number,
  coupons?: CouponDto[]
): EmbeddedCouponDto[] | undefined =>
  coupons?.map((coupon) => ({
    couponCode: coupon.couponCode,
    cashValue: calculateCoupon(paymentAmount, coupon),
  }));

const calculateAdvantages = (values: CashDeskSimulatorProps) => {
  const paymentAmount = calculatePaymentAmount(values.receiptDetailIds);
  const advantageTexts = values.selectedCoupons?.map((coupon) =>
    calculateAdvantage(paymentAmount, coupon)
  );
  return advantageTexts?.join(" | ");
};

const calculateAdvantage = (paymentAmount: number, coupon: CouponDto) => {
  if (coupon && coupon.advantage.advantageValue) {
    if (coupon.advantage.advantageType === "PERCENT")
      return formatEuro(
        (paymentAmount * coupon.advantage.advantageValue) / 100
      );
    if (coupon.advantage.advantageType === "EURO")
      return formatEuro(coupon.advantage.advantageValue);
    if (coupon.advantage.advantageType === "OFFER")
      return coupon.advantage.advantageText;
  }
};

export function calculatePaymentAmount(receiptDetailIds: string[]) {
  return receiptDetailExamples
    .filter((detail) =>
      receiptDetailIds.some(
        (refNumber) => detail.itemInfo.refNumber === Number(refNumber)
      )
    )
    .reduce((salesPrice, detail) => salesPrice + Number(detail.salesPrice), 0);
}

export class CashDeskSimulator extends React.Component<ThunkProps> {
  validate = (cashDeskSimulator: CashDeskSimulatorProps) => {
    const errors: ValidationErrors = {};
    if (!cashDeskSimulator.externalUnitNumber) {
      errors.externalUnitNumber = "Filiale erforderlich";
    }
    return errors;
  };

  initialValues: CashDeskSimulatorProps = {
    externalUnitNumber: "",
    selectedCoupons: undefined,
    cashValue: undefined,
    receiptDetailIds: [],
  };

  render() {
    const {
      thunkCreatePaymentInteraction,
      thunkGetCustomerBonusInfo,
      operationalUnits,
      customerBonusInfo,
      interactionTimestamp,
      customer,
    } = this.props;
    if (interactionTimestamp) return <ReceiptSimulator />;
    return (
      <Form
        validate={this.validate}
        onSubmit={(values) => {
          const paymentAmount = calculatePaymentAmount(values.receiptDetailIds);
          const coupons = calculateCoupons(
            paymentAmount,
            values.selectedCoupons
          );

          thunkCreatePaymentInteraction(
            customer.customerNumber,
            customer.externalCustomerId,
            values.externalUnitNumber,
            paymentAmount,
            values.receiptDetailIds,
            coupons,
            values.selectedCoupons,
            values.cashValue
          );
        }}
        mutators={{
          clearCashValue: (args, state, utils) => {
            utils.changeValue(state, "cashValue", () => undefined);
          },
        }}
        initialValues={this.initialValues}
        render={({
          form,
          handleSubmit,
          submitting,
          hasValidationErrors,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <PaliFinalFormSelect
                  name="externalUnitNumber"
                  label="Filiale auswählen *"
                  options={[{ label: "bitte wählen...", value: "" }].concat(
                    operationalUnits
                      .filter(
                        (operationalUnit) =>
                          operationalUnit.unitType ===
                          OperationalUnitType.BRANCH_STORE
                      )
                      .map((operationalUnit) => ({
                        label: operationalUnit.unitName,
                        value: operationalUnit.externalUnitNumber,
                      }))
                  )}
                  onChange={(e) => {
                    thunkGetCustomerBonusInfo(
                      customer.customerNumber,
                      customer.externalCustomerId,
                      e.target.value
                    );
                  }}
                />
              </Col>
            </Row>

            <ReceiptDetailPanel />

            <CouponPanel customerBonusInfo={customerBonusInfo} form={form} />

            {values.selectedCoupons && (
              <Row>
                <Col md={9}>
                  <FormGroup>
                    <Label>Ihr Vorteil</Label>
                    <Input
                      type="text"
                      readOnly={true}
                      value={calculateAdvantages(values)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}

            <BonusXpressPanel
              customerBonusInfo={customerBonusInfo}
              values={values}
            />
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Rechnungssumme</Label>
                  <Input
                    type="text"
                    readOnly={true}
                    value={formatEuro(
                      calculatePaymentAmountWithCouponAndXpress(
                        calculatePaymentAmount(values.receiptDetailIds),
                        values.cashValue,
                        values.selectedCoupons
                      )
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Button
                  type="submit"
                  className="mt-3"
                  disabled={submitting || hasValidationErrors}
                >
                  Bezahlen
                </Button>
              </Col>
            </Row>
          </form>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    { thunkGetCustomerBonusInfo, thunkCreatePaymentInteraction },
    dispatch
  );

const mapStateToProps = (state: IStore) => ({
  customer: state.customer.customer,
  operationalUnits: state.operationalUnit.operationalUnits,
  customerBonusInfo: state.touchpoint.customerBonusInfo,
  interactionTimestamp: state.touchpoint.interactionTimestamp,
});

export default connect(mapStateToProps, mapDispatchToProps)(CashDeskSimulator);
