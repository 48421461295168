import { ValidationErrors } from "final-form";
import { CustomerDto, CUSTOMER_FIELDS } from "../../store/models/CustomerDto";
import {
  FieldConfigDto,
  getMandatoryFields,
  getOptionalFields,
} from "../../store/models/FieldConfigDto";
import { MANDATORY_TEXT } from "../registration/Validation";
import { ConfigDto } from "../../store/models/ConfigDto";

function getCustomerValueByName(customer: CustomerDto, propertyName: string) {
  return (
    customer &&
    propertyName &&
    propertyName
      .split(".")
      .reduce(
        (result, prop) => (result == null ? undefined : result[prop]),
        customer as any
      )
  );
}
function setCustomerErrors(
  errors: ValidationErrors,
  errorMessage: string,
  propertyName: string
) {
  if (!errors) return;
  if (!propertyName) return errors;
  const props = propertyName.split(".");

  if (props.length === 1) {
    errors[props[0]] = errorMessage;
  } else if (props.length === 2) {
    if (!errors[props[0]]) {
      errors[props[0]] = {};
    }
    errors[props[0]][props[1]] = errorMessage;
  }
}

export function isFieldNeeded(
  fieldName: string,
  customerFieldConfig: FieldConfigDto[]
) {
  if (!customerFieldConfig) return true;
  const mandatoryFields = getMandatoryFields(customerFieldConfig);
  const optionalFields = getOptionalFields(customerFieldConfig);
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");

  return mandatoryFields || optionalFields
    ? mandatoryFields?.some(
        mandatoryField => mandatoryField === fieldKeyFromName
      ) ||
        optionalFields?.some(
          optionalField => optionalField === fieldKeyFromName
        )
    : true;
}
function getMandatoryMap(
  customer: CustomerDto,
  customerFieldConfig: FieldConfigDto[]
): string[] {
  const mandatoryFields = getMandatoryFields(customerFieldConfig);
  if (!mandatoryFields) return [];
  return mandatoryFields.map(prop => (CUSTOMER_FIELDS as any)[prop]);
}
export function validateMandatory(
  errors: ValidationErrors,
  customer: CustomerDto,
  customerFieldConfig: FieldConfigDto[]
) {
  if (!errors) return;
  if (!customerFieldConfig) return errors;

  const mandatoryMap = getMandatoryMap(customer, customerFieldConfig);

  mandatoryMap.forEach(propertyName => {
    if (!getCustomerValueByName(customer, propertyName)) {
      setCustomerErrors(errors, MANDATORY_TEXT, propertyName);
    }
  });
  return errors;
}

export function isVisibleField(fieldName: string, displayConfig: ConfigDto[]) {
  if (!displayConfig.length) return true;
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");

  return displayConfig.some(
    config =>
      config.key === fieldKeyFromName &&
      config.value.toLocaleLowerCase() === "true"
  );
}
