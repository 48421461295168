import { TouchpointApi } from "../../api/TouchpointApi";
import {
  formatEuro,
  getCurrentDateTimeAsString,
  getRandomId,
} from "../../components/app/Utils";
import { receiptDetailExamples } from "../../components/simulator/ReceiptDetailExamples";
import {
  CouponDto,
  EmbeddedCouponDto,
  PaymentInteractionDto,
  RedemptionDto,
  ReversalInteractionDto,
} from "../models/TouchpointDto";
import { ITouchpointReducerState } from "../reducers/TouchpointReducer";
import { BooleanThunk, VoidThunk } from "../rootReducer";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const RESET_INTERACTION = "RESET_INTERACTION";
export const CREATE_INTERACTION = "CREATE_INTERACTION";
export const REDEEM_COUPON = "REDEEM_COUPON";

export const thunkCreatePaymentInteraction =
  (
    customerNumber: string | undefined,
    externalCustomerId: string | undefined,
    externalUnitNumber: string,
    paymentAmount: number,
    receiptDetailIds: string[],
    coupons?: EmbeddedCouponDto[],
    selectedCoupons?: CouponDto[],
    bonusXpressCashValue?: number
  ): BooleanThunk =>
  async (dispatch) => {
    const interactionTimestamp = getCurrentDateTimeAsString();
    const externalReceiptId = getRandomId();

    const bonusXpress = bonusXpressCashValue
      ? { cashValue: bonusXpressCashValue }
      : undefined;
    const receiptDetails = receiptDetailExamples.filter((detail) =>
      receiptDetailIds.some(
        (refNumber) => detail.itemInfo.refNumber === Number(refNumber)
      )
    );
    const paymentInteraction: PaymentInteractionDto = {
      customerNumber,
      externalCustomerId,
      externalReceiptId,
      externalUnitNumber,
      interactionTimestamp,
      paymentAmount,
      coupons,
      bonusXpress,
      receiptDetails,
    };
    try {
      const paymentResponse = await TouchpointApi.createPaymentInteraction(
        paymentInteraction
      );

      const payload: ITouchpointReducerState = {
        customerNumber,
        externalCustomerId,
        externalReceiptId,
        externalUnitNumber,
        interactionTimestamp,
        paymentAmount,
        bonusXpressCashValue,
        receiptDetails,
        coupons,
        selectedCoupons,
        paymentResponse,
      };

      dispatch({
        type: CREATE_INTERACTION,
        payload,
      });

      dispatch(
        thunkCreateSuccessNotification(
          `Zahlung erfasst: ${formatEuro(paymentAmount)}`
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Erfassen der Zahlung", e)
      );
      return false;
    }
  };

export const thunkCreateReversalInteraction =
  (
    reversalInteraction: ReversalInteractionDto,
    selectedCoupons?: CouponDto[]
  ): BooleanThunk =>
  async (dispatch) => {
    reversalInteraction.interactionTimestamp = getCurrentDateTimeAsString();
    reversalInteraction.externalReceiptId = getRandomId();
    try {
      const reversalResponse = await TouchpointApi.createReversalInteraction(
        reversalInteraction
      );
      const payload: ITouchpointReducerState = {
        ...reversalInteraction,
        paymentAmount: reversalInteraction.reversalAmount,
        bonusXpressReversalCashValue:
          reversalInteraction.bonusXpress?.reversalCashValue,
        reversalResponse,
        selectedCoupons,
      };
      dispatch({
        type: CREATE_INTERACTION,
        payload,
      });

      dispatch(
        thunkCreateSuccessNotification(
          `Storno erfasst: ${formatEuro(reversalInteraction.reversalAmount)}`
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Erfassen des Storno", e)
      );
      return false;
    }
  };

export const thunkResetInteraction = (): VoidThunk => async (dispatch) => {
  dispatch({
    type: RESET_INTERACTION,
  });
};

export const GET_CUSTOMER_BONUS_INFO = "GET_CUSTOMER_BONUS_INFO";

export const thunkGetCustomerBonusInfo =
  (
    customerNumber: string | undefined,
    externalCustomerId: string | undefined,
    externalUnitNumber: string
  ): BooleanThunk =>
  async (dispatch) => {
    try {
      const identType = customerNumber
        ? "customerNumber"
        : "externalCustomerId";
      const customerIdent = customerNumber || externalCustomerId!;
      const customerBonusInfo = await TouchpointApi.getCustomerBonusInfo(
        identType,
        customerIdent,
        externalUnitNumber
      );
      dispatch({
        type: GET_CUSTOMER_BONUS_INFO,
        payload: customerBonusInfo,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler beim Laden der Bonus XPress Kundendaten",
          e
        )
      );
      return false;
    }
  };

export const thunkRedeemCoupon =
  (redemption: RedemptionDto): BooleanThunk =>
  async (dispatch) => {
    try {
      if (!redemption.couponCode) return false;
      const redeemedCoupon = await TouchpointApi.redeemCoupon(redemption);
      dispatch({
        type: REDEEM_COUPON,
        payload: redeemedCoupon,
      });
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification("Fehler beim Einlösen des Coupons", e)
      );
      return false;
    }
  };
