import { ValidationErrors } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { ConsentDto } from "../../store/models/ConsentDto";
import { nameof } from "../app/Utils";
import NotificationList from "../notification/NotificationList";
import { PaliFinalFormCheckbox } from "../pali/PaliFinalFormCheckbox";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";
import { validateDateOfBirth } from "../registration/Validation";
import { ConsentFormThunkProps } from "./ConsentFormContainer";
import { validateExternalCustomerId, validateMandatory } from "./Validation";

export default class ConsentForm extends React.Component<ConsentFormThunkProps> {
  render() {
    const { thunkConsent } = this.props;

    const validate = (consent: ConsentDto) => {
      const errors: ValidationErrors = {};

      validateExternalCustomerId(errors, consent.externalCustomerId);
      validateDateOfBirth(errors, consent.dateOfBirth);
      validateMandatory(errors, consent);

      return errors;
    };
    return (
      <div className="jumbotron">
        <h1>Kundeneinwilligung</h1>
        <Form
          onSubmit={thunkConsent}
          initialValues={{} as ConsentDto}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Card id="personal" className="mb-5">
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <PaliFinalFormInput
                        type="text"
                        name={nameof<ConsentDto>("externalCustomerId")}
                        label="Vorteilskartennummer *"
                      />
                    </Col>
                    <Col md={6}>
                      <PaliFinalFormInput
                        type="date"
                        name={nameof<ConsentDto>("dateOfBirth")}
                        label="Geburtsdatum *"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <PaliFinalFormInput
                        type="email"
                        name={nameof<ConsentDto>("mailAddress")}
                        label="E-Mail"
                        autoComplete="email"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <PaliFinalFormCheckbox
                        name={nameof<ConsentDto>("participationConsent")}
                        label="Ich habe die Allgemeinen Geschäftsbedingungen gelesen und akzeptiere diese."
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row style={{ paddingBottom: 16 }}>
                <Col md={{ size: 12 }}>
                  <NotificationList />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button type="submit">Absenden</Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </div>
    );
  }
}
