import React from "react";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";

import { thunkInviteFriend } from "../../store/actions/InvitationActions";

import { IStore } from "../../store/rootReducer";
import { MenuLayout } from "../../themes/SwitchTheme";

type ThunkProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

class InviteFriendPage extends React.Component<ThunkProps> {
  render() {
    const { thunkInviteFriend } = this.props;
    return (
      <MenuLayout>
        <h1>Freunde einladen</h1>
        <Form
          onSubmit={(form) => thunkInviteFriend(form.mailAddress)}
          render={({
            handleSubmit,
            submitting,
            hasValidationErrors,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <PaliFinalFormInput
                    type="email"
                    name="mailAddress"
                    id="friendMailAddress"
                    label="E-Mail deiner Freundin / deines Freundes"
                    help="Sie / Er sammelt dann auf ein eigenes persönliches Punktekonto"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-3 mb-5">
                  <Button type="submit">Einladen</Button>
                </Col>
              </Row>
            </form>
          )}
        />
      </MenuLayout>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkInviteFriend }, dispatch);

const mapStateToProps = (state: IStore) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriendPage);
