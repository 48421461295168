import { FieldValidator } from "final-form";
import React from "react";
import { Field } from "react-final-form";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";

interface PaliFinalFormInputProps {
  type: InputType;
  name: string;
  id?: string;
  label: string;
  help?: string;
  readOnly?: boolean;
  autoComplete?: string;
  format?: (val: any) => any;
  parse?: (val: any) => any;
  validate?: FieldValidator<any>;
}

export class PaliFinalFormInput extends React.Component<PaliFinalFormInputProps> {
  render() {
    const {
      label,
      type,
      help,
      name,
      id,
      readOnly,
      format,
      parse,
      validate,
      autoComplete,
    } = this.props;
    return (
      <Field name={name} format={format} parse={parse} validate={validate}>
        {({ input, meta }) => {
          const invalid = meta.error && meta.touched;
          return (
            <FormGroup className={invalid ? "has-error" : ""}>
              <Label for={id ? id : name}>{label}</Label>
              <Input
                {...input}
                autoComplete={autoComplete}
                type={type}
                id={id ? id : name}
                readOnly={readOnly}
              />
              {invalid && <span className="help-block">{meta.error}</span>}
              {help && <FormText>{help}</FormText>}
            </FormGroup>
          );
        }}
      </Field>
    );
  }
}
