import React from "react";
import CustomerFormContainer from "./CustomerFormContainer";
import { MenuLayout } from "../../themes/SwitchTheme";

export default class CustomerPage extends React.Component {
  render() {
    return (
      <MenuLayout>
        <CustomerFormContainer />
      </MenuLayout>
    );
  }
}
