import API from "../../api/Api";
import { ConsentDto } from "../models/ConsentDto";
import { BooleanThunk } from "../rootReducer";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const CONSENT_SUCCESS = "CONSENT_SUCCESS";

export const thunkConsent =
  (consent: ConsentDto): BooleanThunk =>
  async (dispatch) => {
    try {
      const consentResult = await API.consent(consent);
      dispatch({
        type: CONSENT_SUCCESS,
        payload: consentResult,
      });
      dispatch(
        thunkCreateSuccessNotification(
          "Ihre Kundeneinwilligung wurde erfolgreich übermittelt."
        )
      );
      return true;
    } catch (e) {
      dispatch(
        thunkCreateErrorNotification(
          "Fehler bei der Kundeneinwilligung. Bitte melden Sie sich per E-Mail (service@engelhorn.de) oder telefonisch (+49 (0)621 167 0100).",
          e
        )
      );
      return false;
    }
  };
