import API from "../../api/Api";
import { BooleanThunk } from "../rootReducer";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
} from "./NotificationActions";

export const thunkCancelMembership = (): BooleanThunk => async (dispatch) => {
  try {
    await API.cancelMembership();
    dispatch(
      thunkCreateSuccessNotification("Kündigung erfolgreich übermittelt")
    );
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Fehler bei der Kündigung", e));
    return false;
  }
};
