import React from "react";
import { Field } from "react-final-form";
import { FormGroup, Input, Label } from "reactstrap";

interface PaliRadioButtonProps {
  value?: any;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label?: string;
  options: any[];
  row?: boolean;
}

export class PaliRadioButton extends React.Component<PaliRadioButtonProps> {
  render() {
    const { label, name, options, value, handleChange, row } = this.props;
    return (
      <Field name={name} type="radio" value={value}>
        {({ input, meta }) => {
          const hasErrors = meta.error && meta.touched;
          return (
            <div>
              <FormGroup style={{ display: row ? "flex" : "block" }}>
                <p>{label}</p>
                {options.map(({ label, value }, index) => (
                  <React.Fragment key={"radioBtnKey" + value}>
                    <Label
                      className="check check--radio"
                      style={{ paddingRight: row ? "20px" : "0" }}
                    >
                      {label}
                      <Input
                        type="radio"
                        name={name}
                        id={name + value + index}
                        value={value}
                        defaultChecked={value === input.value}
                        onChange={(e) => {
                          input.onChange(e);
                          handleChange && handleChange(e);
                        }}
                      />
                      <div className="check__indicator"></div>
                    </Label>
                    <br />
                  </React.Fragment>
                ))}
              </FormGroup>
              {hasErrors && (
                <span
                  className="help-block"
                  style={{
                    marginTop: row ? "-15px" : "0",
                    paddingBottom: row ? "15px" : 0,
                  }}
                >
                  {meta.error}
                </span>
              )}
            </div>
          );
        }}
      </Field>
    );
  }
}
