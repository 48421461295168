import countries from "iso3166-2-db/countryList/dispute/UN/de.json";
import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import AddressDto from "../../store/models/AddressDto";
import { RegistrationDto } from "../../store/models/RegistrationDto";
import { nameof, nameof2 } from "../app/Utils";
import { PaliFinalFormInput } from "../pali/PaliFinalFormInput";
import { PaliFinalFormSelect } from "../pali/PaliFinalFormSelect";
import { getLabelText } from "./RegistrationForm";
import { RegistrationFormThunkProps } from "./RegistrationFormContainer";
import { isFieldNeeded } from "./Validation";

const countryList = Object.entries(countries)
  .map(([k, v]) => ({
    value: k,
    label: v.name,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

export default class Contact extends React.Component<RegistrationFormThunkProps> {
  render() {
    const { registrationFieldConfig } = this.props;
    return (
      <Card id="contact" className="mb-5">
        <CardBody>
          <CardTitle>Kontaktinformationen</CardTitle>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof2<RegistrationDto, AddressDto>("address", "streetName"),
                registrationFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof2<RegistrationDto, AddressDto>(
                    "address",
                    "streetName"
                  )}
                  label={getLabelText(
                    "Straße",
                    nameof2<RegistrationDto, AddressDto>(
                      "address",
                      "streetName"
                    ),
                    registrationFieldConfig
                  )}
                />
              )}
            </Col>
            <Col md={3}>
              {isFieldNeeded(
                nameof2<RegistrationDto, AddressDto>("address", "houseNumber"),
                registrationFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof2<RegistrationDto, AddressDto>(
                    "address",
                    "houseNumber"
                  )}
                  label={getLabelText(
                    "Hausnummer",
                    nameof2<RegistrationDto, AddressDto>(
                      "address",
                      "houseNumber"
                    ),
                    registrationFieldConfig
                  )}
                />
              )}
            </Col>
            <Col md={3}>
              {isFieldNeeded(
                nameof2<RegistrationDto, AddressDto>(
                  "address",
                  "addressAddition"
                ),
                registrationFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof2<RegistrationDto, AddressDto>(
                    "address",
                    "addressAddition"
                  )}
                  label={getLabelText(
                    "Adresszusatz",
                    nameof2<RegistrationDto, AddressDto>(
                      "address",
                      "addressAddition"
                    ),
                    registrationFieldConfig
                  )}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof2<RegistrationDto, AddressDto>("address", "zipCode"),
                registrationFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="number"
                  name={nameof2<RegistrationDto, AddressDto>(
                    "address",
                    "zipCode"
                  )}
                  label={getLabelText(
                    "Postleitzahl",
                    nameof2<RegistrationDto, AddressDto>("address", "zipCode"),
                    registrationFieldConfig
                  )}
                />
              )}
            </Col>
            <Col md={6}>
              {isFieldNeeded(
                nameof2<RegistrationDto, AddressDto>("address", "cityName"),
                registrationFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof2<RegistrationDto, AddressDto>(
                    "address",
                    "cityName"
                  )}
                  label={getLabelText(
                    "Ort",
                    nameof2<RegistrationDto, AddressDto>("address", "cityName"),
                    registrationFieldConfig
                  )}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof2<RegistrationDto, AddressDto>("address", "country"),
                registrationFieldConfig
              ) && (
                <PaliFinalFormSelect
                  name={nameof2<RegistrationDto, AddressDto>(
                    "address",
                    "country"
                  )}
                  label={getLabelText(
                    "Land",
                    nameof2<RegistrationDto, AddressDto>("address", "country"),
                    registrationFieldConfig
                  )}
                  options={countryList}
                />
              )}
            </Col>
            <Col md={6}>
              {isFieldNeeded(
                nameof<RegistrationDto>("phoneNumber"),
                registrationFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<RegistrationDto>("phoneNumber")}
                  label={getLabelText(
                    "Telefon",
                    nameof<RegistrationDto>("phoneNumber"),
                    registrationFieldConfig
                  )}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {isFieldNeeded(
                nameof<RegistrationDto>("mobileNumber"),
                registrationFieldConfig
              ) && (
                <PaliFinalFormInput
                  type="text"
                  name={nameof<RegistrationDto>("mobileNumber")}
                  label={getLabelText(
                    "Mobil",
                    nameof<RegistrationDto>("mobileNumber"),
                    registrationFieldConfig
                  )}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
