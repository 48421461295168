import { ReceiptDetailDto } from "../../store/models/CustomerInteractionDto";

export const receiptDetailExamples: ReceiptDetailDto[] = [
  {
    amount: "1.000",
    salesPrice: "19.90",
    originalPrice: "19.90",
    itemInfo: {
      productGroupNumber: 410,
      productGroupDesc: "Home and Living",
      subGroupNumber: 810,
      subGroupDesc: "Trinkflaschen",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Isolierflasche",
      refNumber: 100000100,
      rating: 3.5,
      votings: 107,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "37690100",
    },
  },
  {
    amount: "1.000",
    salesPrice: "49.90",
    originalPrice: "49.90",
    itemInfo: {
      productGroupNumber: 420,
      productGroupDesc: "Bekleidung",
      subGroupNumber: 820,
      subGroupDesc: "Pullover und Hoodies",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: 'Classic Hooded Sweatshirt "navi"',
      refNumber: 100000200,
      rating: 4,
      votings: 284,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "4049602357187",
    },
  },
  {
    amount: "1.000",
    salesPrice: "59.90",
    originalPrice: "59.90",
    itemInfo: {
      productGroupNumber: 420,
      productGroupDesc: "Bekleidung",
      subGroupNumber: 820,
      subGroupDesc: "Pullover und Hoodies",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: 'Hoodiekleid "knistrBlack"',
      refNumber: 100000201,
      rating: 5,
      votings: 231,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "40412357187",
    },
  },
  {
    amount: "1.000",
    salesPrice: "19.90",
    originalPrice: "19.90",
    itemInfo: {
      productGroupNumber: 420,
      productGroupDesc: "Bekleidung",
      subGroupNumber: 830,
      subGroupDesc: "Babys",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Baby Bodysuit (100% Bio-Baumwolle)",
      refNumber: 100000250,
      rating: 3,
      votings: 769,
      organic: true,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "453976856",
    },
  },
  {
    amount: "1.000",
    salesPrice: "17.99",
    originalPrice: "17.99",
    itemInfo: {
      productGroupNumber: 420,
      productGroupDesc: "Bekleidung",
      subGroupNumber: 840,
      subGroupDesc: "Damen Armband",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: "Baseballshirt langarm",
      refNumber: 100000210,
      rating: 4,
      votings: 54,
      organic: false,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "3456897345789",
    },
  },
  {
    amount: "1.000",
    salesPrice: "14.90",
    originalPrice: "14.90",
    itemInfo: {
      productGroupNumber: 420,
      productGroupDesc: "Bekleidung",
      subGroupNumber: 850,
      subGroupDesc: "T-Shirts",
      supplierNumber: 2022,
      supplierDesc: "Knistr",
      supplierItemText: 'Damen Organic T-Shirt "black"',
      refNumber: 100000220,
      rating: 4.5,
      votings: 491,
      organic: true,
      salesAreaNumber: 123,
      salesAreaDesc: "salesAreaDesc",
      ean: "23486423867",
    },
  },
];
