import React, { PropsWithChildren } from "react";
import { Col, Container, Row } from "reactstrap";
import NotificationList from "../../../components/notification/NotificationList";
import { Header } from "./Header";

export class MenuLayout extends React.Component<PropsWithChildren> {
  render() {
    return (
      <>
        <div style={{ position: "absolute", width: "100%", zIndex: 1 }}>
          <NotificationList />
        </div>
        <Container className="full-height" style={{ maxWidth: "unset" }}>
          <Row className="full-height">
            <Col sm={{ size: 2 }} className="ps-0">
              <Header />
            </Col>
            <Col sm={{ size: 10 }} className="pt-3">
              {this.props.children}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
