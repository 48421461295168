import { ValidationErrors } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { Button, Col, Row } from "reactstrap";
import { ChangePasswordDto } from "../../store/models/ChangePasswordDto";
import ChangePasswordData from "./ChangePasswordData";
import { validateForm } from "./Validation";

export type ChangePasswordFormProps = {
  changePassword: ChangePasswordDto;
  handleSubmit: (changePassword: ChangePasswordDto) => void;
};
export type ChangePasswordFormChildProps = {
  changePassword: ChangePasswordDto;
};

interface ChangePasswordFormState {
  changePassword: ChangePasswordDto;
}
export default class ChangePasswordForm extends React.Component<
  ChangePasswordFormProps,
  ChangePasswordFormState
> {
  constructor(props: ChangePasswordFormProps) {
    super(props);
    this.state = { changePassword: props.changePassword };
  }

  validate = (changePassword: ChangePasswordDto) => {
    const errors: ValidationErrors = {};
    validateForm(errors, changePassword);
    return errors;
  };

  render() {
    const { handleSubmit } = this.props;
    const { changePassword } = this.state;

    return (
      <>
        <div>
          <Form
            onSubmit={handleSubmit}
            initialValues={changePassword}
            validate={this.validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ChangePasswordData changePassword={changePassword} />
                <Row>
                  <Col md={12}>
                    <Button type="submit">Neues Kennwort einrichten</Button>
                  </Col>
                </Row>
              </form>
            )}
          />
        </div>
      </>
    );
  }
}
