import React from "react";
import { MenuLayout } from "../../themes/SwitchTheme";
import BonifiedInteractionsTable from "./BonifiedInteractionsTable";

export default class BonifiedInteractionsPage extends React.Component {
  render() {
    return (
      <MenuLayout>
        <h1>Bonuspunkte</h1>
        <BonifiedInteractionsTable />
      </MenuLayout>
    );
  }
}
