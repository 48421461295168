import React from "react";

import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";

interface PaliTableProps {
  data: any[];
  columns: ColumnDescription[];
}

export class PaliTable extends React.Component<PaliTableProps> {
  render() {
    const { data, columns } = this.props;
    return (
      <BootstrapTable
        bootstrap4={true}
        keyField="id"
        data={data}
        columns={columns}
        bordered={false}
      />
    );
  }
}
